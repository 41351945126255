<div class="row animated fadeIn fast">

    <div class="col-lg-12">
        <div class="card">

            <div class="card-body">
                <form class="form p-t-20"
                      [formGroup]="cliForm"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">
                            <div class="col ml-2 mb-2">
                                <h4><b>{{ cliente.nombre }}</b></h4>
                                <span class="ml-2">{{ cliente.direccion }} ({{ cliente.cp }} - {{ cliente.poblacion }}) </span>
                            </div>
                        </div>
                        <hr>

                        <div class="card mt-2 border border-secondary rounded configCard">

                            <div class="card-body mt-0">

                                <div class="row">

                                    <!-- acceso_intranet -->
                                    <div class="col-md-12">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['acceso_intranet'].errors }">
                                            <label> <i class="fa-solid fa-user-tie"></i> Acceso a Intranet de cliente</label>
                                            <div class="custom-control custom-radio">
                                                <input formControlName="acceso_intranet"
                                                       type="radio"
                                                       class="custom-control-input"
                                                       id="acceso_si"
                                                       value="S"
                                                       [checked]="cliForm.get('acceso_intranet')?.value === 'S'"
                                                       (change)="accesoIntranetChange()">
                                                <label class="custom-control-label"
                                                       for="acceso_si">Sí</label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                                <input formControlName="acceso_intranet"
                                                       type="radio"
                                                       class="custom-control-input"
                                                       id="acceso_no"
                                                       value="N"
                                                       [checked]="cliForm.get('acceso_intranet')?.value === 'N'"
                                                       (change)="accesoIntranetChange()">
                                                <label class="custom-control-label"
                                                       for="acceso_no">No</label>
                                            </div>
                                            <div *ngIf="enviado && f['acceso_intranet'].errors"
                                                 class="form-control-feedback">
                                                <div *ngIf="cliForm.get('acceso_intranet')?.errors?.['apiError']">
                                                    {{cliForm.get('acceso_intranet')?.errors?.['apiError']}}
                                                </div>
                                            </div>
                                            <p>
                                                <small class="text-info mb-0 ml-2">¿El cliente podrá tener usuarios de Intranet?</small>
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <hr *ngIf="cliForm.get('acceso_intranet')?.value === 'S'"
                                    class="text-info mt-0 mb-3">

                                <div class="row">

                                    <!-- email_intranet -->
                                    <div class="col-md-3"
                                         *ngIf="cliForm.get('acceso_intranet')?.value === 'S'">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['email_intranet'].errors }">
                                            <label class="custom-control-label"
                                                   for="email_intranet"
                                                   data-toggle="tooltip"
                                                   title="A esta dirección de eMail se enviarán los avisos. De cliente -> A sga InnovaCen">
                                                Email de recepción de solicitudes:
                                                <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                            </label>

                                            <input type="text"
                                                   formControlName="email_intranet"
                                                   class="form-control"
                                                   [ngClass]="{ 'form-control-danger': enviado && f['email_intranet'].errors }">

                                            <div *ngIf="enviado && f['email_intranet'].errors"
                                                 class="form-control-feedback">
                                                <div *ngIf="f['email_intranet'].errors['required']">Obligatorio</div>
                                                <div *ngIf="f['email_intranet'].errors['eMailFormat']">No parece un eMail válido</div>
                                                <div *ngIf="cliForm.get('email_intranet')?.errors?.['apiError']">{{cliForm.get('email_intranet')?.errors?.['apiError'] }}</div>
                                            </div>
                                            <small>
                                                <i>(De cliente -> A InnovaCen)</i>
                                            </small>
                                        </div>
                                    </div>


                                    <!-- agrupa_ubicaciones -->
                                    <div class="col-md-3"
                                         *ngIf="cliForm.get('acceso_intranet')?.value === 'S'">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['agrupa_ubicaciones'].errors }">
                                            <div class="custom-control custom-switch">
                                                <input formControlName="agrupa_ubicaciones"
                                                       type="checkbox"
                                                       class="custom-control-input"
                                                       id="agrupa_ubicaciones">

                                                <label class="custom-control-label"
                                                       for="agrupa_ubicaciones"
                                                       data-toggle="tooltip"
                                                       title="El cliente verá su Stock agrupado, sin diferenciar entre ubicaciones de un mismo artículo">
                                                    Agrupar ubicaciones del Stock
                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                </label>

                                            </div>
                                        </div>
                                    </div>


                                    <!-- intranet_import_pedidos -->
                                    <div class="col-md-3"
                                         *ngIf="cliForm.get('acceso_intranet')?.value === 'S'">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['intranet_import_pedidos'].errors }">
                                            <div class="custom-control custom-switch">
                                                <input formControlName="intranet_import_pedidos"
                                                       type="checkbox"
                                                       class="custom-control-input"
                                                       id="intranet_import_pedidos">

                                                <label class="custom-control-label"
                                                       for="intranet_import_pedidos"
                                                       data-toggle="tooltip"
                                                       title="El cliente podrá importar ficheros .xlsx con pedidos desde la intranet en formato cabecera detalle">
                                                    Puede Importar ficheros excel de pedidos
                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                </label>

                                            </div>
                                        </div>
                                    </div>

                                    <!-- intranet_origen_pedidos -->
                                    <div class="col-md-3"
                                         *ngIf="cliForm.get('acceso_intranet')?.value === 'S'">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['intranet_origen_pedidos'].errors }">
                                            <div class="custom-control custom-switch">
                                                <input formControlName="intranet_origen_pedidos"
                                                       type="checkbox"
                                                       class="custom-control-input"
                                                       id="intranet_origen_pedidos">

                                                <label class="custom-control-label"
                                                       for="intranet_origen_pedidos"
                                                       data-toggle="tooltip"
                                                       title="El cliente podrá consultar el origen de los pedidos procesados ">
                                                    Puede consultar el origen de pedidos
                                                    <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                                </label>

                                            </div>
                                        </div>
                                    </div>

                                </div> <!-- row -->

                                <hr *ngIf="cliForm.get('acceso_intranet')?.value === 'S'"
                                    class="text-info mt-0 mb-3">

                                <div class="row">

                                    <!-- codServicios_posibles -->
                                    <div class="col-md-3"
                                         *ngIf="cliForm.get('acceso_intranet')?.value === 'S'">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['codServicios_posibles'].errors }">
                                            <label class="custom-control-label"
                                                   for="codServicios_posibles"
                                                   data-toggle="tooltip"
                                                   title="El cliente podrá seleccionar uno de estos típos de servicio para cada pedido creado desde la intranet">
                                                Servicios seleccionables
                                                <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                            </label>

                                            <div class="border p-2"
                                                 style="height: 150px; overflow-y: auto; border-radius: 5px;">
                                                <div id="checkboxContainer"
                                                     *ngFor="let option of serviciosList; let i = index"
                                                     class="form-check"
                                                     [ngClass]="{ 'highlight-inte': option.codServicio === 'inte' }">
                                                    <input type="checkbox"
                                                           class="form-check-input"
                                                           [id]="'option' + i"
                                                           [value]="option.codServicio"
                                                           (change)="onCodServicioChange($event)"
                                                           [checked]="codServicios_seleccionados.includes(option.codServicio)" />
                                                    <label class="form-check-label"
                                                           [for]="'option' + i">
                                                        {{ option.nombre }}
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="mt-3">
                                                <span *ngFor="let codServ of codServicios_seleccionados"
                                                      [ngClass]="{'badge-primary': codServ != 'inte', 'badge-warning': codServ == 'inte'}"
                                                      class="badge mr-1">
                                                    {{ codServ }}
                                                </span>
                                            </div>

                                            <div *ngIf="enviado && f['codServicios_posibles'].errors"
                                                 class="form-control-feedback">
                                                <div *ngIf="cliForm.get('codServicios_posibles')?.errors?.['apiError']">{{
                                                    cliForm.get('codServicios_posibles')?.errors?.['apiError'] }}</div>
                                            </div>

                                        </div>

                                    </div>




                                    <!-- codServicio_pred -->
                                    <div class="col-md-3"
                                         *ngIf="cliForm.get('acceso_intranet')?.value === 'S'">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['codServicio_pred'].errors }">
                                            <label class="form-control-label"
                                                   data-toggle="tooltip"
                                                   title="Servicio por defecto al crear nuevos pedidos externos desde la Intranet de Cliente">
                                                Servicio predetermiado:
                                                <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                            </label>
                                            <select formControlName="codServicio_pred"
                                                    class="form-control"
                                                    [ngClass]="{ 'form-control-danger': enviado && f['codServicio_pred'].errors }">
                                                <option class=" font-bold"
                                                        value="0">Indiferente</option>
                                                <option *ngFor="let servicio of serviciosList"
                                                        [value]="servicio.codServicio"
                                                        [disabled]="!codServicios_seleccionados.includes(servicio.codServicio)">
                                                    {{servicio.nombre}}
                                                </option>
                                            </select>
                                            <div *ngIf="enviado && f['codServicio_pred'].errors"
                                                 class="form-control-feedback">
                                                <div *ngIf="cliForm.get('codServicio_pred')?.errors?.['apiError']">{{
                                                    cliForm.get('codServicio_pred')?.errors?.['apiError'] }}</div>
                                            </div>
                                        </div>
                                    </div>


                                </div> <!-- row -->

                                <hr *ngIf="cliForm.get('acceso_intranet')?.value === 'S'"
                                    class="text-info mt-0 mb-3">

                                <div class="row">


                                    <!-- usuariosList -->
                                    <div class="col-md-12"
                                         *ngIf="cliForm.get('acceso_intranet')?.value === 'S'">
                                        <div class="form-group">
                                            <label class="form-control-label"
                                                   data-toggle="tooltip"
                                                   title="Usuarios con acceso a la intranet para este Cliente">
                                                Usuarios Intranet:
                                                <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                            </label>

                                            <div *ngIf="usuariosList?.length! > 0; else noUsuarios">
                                                <table class="table table-sm table-bordered rounded border-5">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Usuario</th>
                                                            <th>Rol</th>
                                                            <th>Nombre</th>
                                                            <th>eMail</th>
                                                            <th>Editar</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let usuario of usuariosList">
                                                            <td>
                                                                <img [src]="fotoDir +'/users/'+ usuario.foto"
                                                                     loading="lazy"
                                                                     class="rounded-circle"
                                                                     style="width:30px" />
                                                            </td>
                                                            <td>{{usuario.username}}</td>
                                                            <td>
                                                                <span class="badge"
                                                                      [ngClass]="{
                                        'badge-success': usuario.rolIntranet === 'admin', 
                                        'badge-light': usuario.rolIntranet !== 'admin'
                                        }"> {{usuario.rolIntranet}}</span>
                                                            </td>
                                                            <td>{{usuario.nombre}} {{usuario.apellidos}}</td>
                                                            <td>{{usuario.email}}</td>
                                                            <td title="Editar usuario">
                                                                <a (click)="irAEditarUsuario(usuario)"
                                                                   class="text-secondary"
                                                                   style="cursor: pointer;">
                                                                    <i class="fa-solid fa-pen-to-square"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <ng-template #noUsuarios>
                                                <ul>
                                                    <li>
                                                        Sin usuarios asignados
                                                    </li>
                                                </ul>
                                            </ng-template>

                                        </div>

                                        <div *ngIf="cliente.id"
                                             class="text-left mb-2">
                                            <button type="button"
                                                    class="btn btn-sm btn-secondary"
                                                    (click)="newUser()"
                                                    data-toggle="tooltip"
                                                    title="Crear un nuevo usuario con acceso a la Intranet de Clientes para este cliente">
                                                <i class="fa-solid fa-user"></i> Crear nuevo usuario de Intranet
                                            </button>
                                        </div>

                                    </div>



                                </div> <!-- row -->

                            </div> <!-- card-body  -->
                        </div> <!-- card -->




                        <hr class="my-4">

                        <div class="d-flex">
                            <button [disabled]="loading"
                                    type="button"
                                    class="btn waves-effect waves-light btn-rounded btn-secondary"
                                    [routerLink]="'/panel/clientes_lista'"
                                    data-dismiss="modal">Cancelar</button>
                            <h6 class="card-subtitle ml-4 ">
                                <li *ngIf="cliente.created_at"> Creado hace {{cliente.created_at! | friendlyDdmmyy}}</li>
                                <li *ngIf="cliente.updated_at"> Última modificación hace {{cliente.updated_at! | friendlyDdmmyy}}</li>
                            </h6>
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Enviar</button>
                        </div>


                    </div> <!-- form-body -->
                </form>

            </div> <!-- card-body -->

        </div> <!-- card -->
    </div> <!-- col-12 -->

</div> <!-- fadeIn fast -->