import * as Constantes from '../../../shared/constants';

// módulos
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// interfaces
import { IApiResp } from 'src/app/interfaces/api-resp';
import { IArtPreparacion } from 'src/app/interfaces/artPreparacion';
import { ICampanya } from 'src/app/interfaces/campanya';
import { ICliente } from 'src/app/interfaces/cliente';

// servicios
import { RepartosService } from 'src/app/services/datos/repartos.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// librerias y entorno
import { environment } from 'src/environments/environment';
import { ColumnMode } from '@swimlane/ngx-datatable';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import * as XLSX from 'xlsx';
import { PreparacionesService } from 'src/app/services/datos/preparaciones.service';

@Component({
  selector: 'app-reubicar',
  templateUrl: './reubicar.component.html',
  styleUrls: ['./reubicar.component.scss']
})
export class ReubicarComponent implements OnInit {

  //Usar constantes en el template
  Constantes = Constantes;

  id_campanya: string = '';

  public campForm: FormGroup = new FormGroup({});
  public enviado: boolean = false;
  public loading: boolean = false;

  public fotoDir: string = environment.fotoDir;

  public lista: IArtPreparacion[] = [];
  public userList: any[] = [];
  public campanyaInfo: ICampanya = {};
  public clienteInfo: ICliente = {};
  public pedidoCero: any = {};

  private listaPedidos: any[] = [];

  public rows: any = [];
  public temp: any[] = [];

  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';
  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  textoDestacado: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: `total`,
    selectedMessage: `seleccionados`
  }

  ColumnMode = ColumnMode;
  @ViewChild(ReubicarComponent) table: ReubicarComponent | any;

  constructor(
    private fb: FormBuilder,
    private RepartoSrv: RepartosService,
    private usuarioSrv: UsuarioService,
    private prepSrv: PreparacionesService,
    private notificacionesSrv: NotificacionesService,
    private activatedRoute: ActivatedRoute
  ) {

  }



  ngOnInit(): void {
    // Suscribirse a los parámetros de la url, si envían una id, cargar los artículos de la campanya
    this.activatedRoute.params.subscribe(({ id_campanya }) => {
      this.id_campanya = id_campanya;
      this.cargaPreparacionesList();
    })
  }


  cargaPreparacionesList() {
    this.lista = [];
    this.loading = true;
    this.prepSrv.getPreparacionPorCampanya(parseInt(this.id_campanya))
      .subscribe(resp => {
        this.loading = false;

        console.log('__cargaPreparacionesList resp', resp);

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          return
        }

        this.campanyaInfo = resp.totales.campanya;
        this.clienteInfo = resp.totales.cliente;
        this.pedidoCero = resp.totales.pedidos[0];

        // Añadir el estado de la preparación a la lista 
        this.lista = resp.data

        console.log('__lista', this.lista);

        // Generar el array listaPedidos con los id's de los pedidos de la campaña
        if (resp.totales) {
          this.listaPedidos = resp.totales.pedidos.map((pedido: any) => {
            return {
              id_pedido: pedido.id,
              cajetin: pedido.cajetin,
              articulo: {}
            };
          });
        }
        console.log('listaPedidos', this.listaPedidos);


        // Aviso de  campañas anteriores
        if (resp.totales && resp.totales.existeAnterior) {
          this.notificacionesSrv.aviso('warning', 'ATENCIÓN, Para este cliente existen campañas anteriores a esta en estado pendiente ó parcialmente asignadas');
        }

        this.rows = this.lista;
        this.temp = [...this.lista];


      })

  }

  // Editar una fila
  editRow(row: any) {
    console.log('editRow', row);
  }


  enviar() {
    this.enviado = true;
    console.log(this.campForm);

    if (this.campForm.invalid) {
      console.log("ERRORES:", this.campForm);
      return;
    }




    // Todo ok, enviar al BackEnd 
    const datos = {
      ...this.campForm.value,
      fecha_entrada: new Date(),
      campanyaInfo: this.campanyaInfo
    }

    this.loading = true;
    console.log("_datos=", datos);
    this.prepSrv.asignaUsrPreparacion(datos)
      .subscribe((resp: IApiResp) => {
        this.loading = false;

        console.log(resp);

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.campForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', resp.mensaje);
          // this.router.navigateByUrl(`panel/campanyas_lista`);  
          this.cargaPreparacionesList();
          return
        }
      })
  }




  get f() {
    return this.campForm.controls;
  }


  //  **  AUX  ** 

  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );
    this.textoDestacado = txt;
    this.rows = temp;
    this.table = this.lista;
  }

  resaltarTexto(value: string | null, textoDestacado: string) {
    return value ? value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>') : '';
  }



  // Exportar a Excel
  exportToExcel(): void {
    const fileName = `recogidas reubicar-${new Date().toISOString()}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();

    // Forzar valores numéricos en las columnas Xlsx
    Object.entries(worksheet).forEach(([cell, cellObj]) => {
      if (!cell.startsWith('!') && !isNaN(cellObj.v) && cellObj.v !== '') {
        cellObj.v = Number(cellObj.v);
        cellObj.t = 'n';
      }
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  }




  ///////////   AUX   ///////////// 



  public sumaCeldas(cells: any[]) {
    // Devuelve la suma de la columna, formateada y sin decimales
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    // return sum.toLocaleString('es');
    return sum.toLocaleString('de-DE');
  }

  public cuentaCeldas(cells: any[]) {
    // Devuelve el número de filas
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + 1, 0);
    return sum.toLocaleString('de-DE');
  }



  // ** Selección de líneas  ** //  


  limitarYAgregarPuntos(value: string, maxLength: number): string {
    // Acorta un string a maxLength caracteres
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    } else {
      return value;
    }
  }


  sumarCantidades(): { cantRecogida: number, cantReserva: number } {
    let cantRecogidaTotal = 0;
    let cantReservaTotal = 0;

    for (const elemento of this.lista) {
      // Convertir las cantidades a números antes de sumar
      cantRecogidaTotal += +elemento.cant_recogida!;
      cantReservaTotal += +elemento.cant_reserva!;
    }

    return { cantRecogida: cantRecogidaTotal, cantReserva: cantReservaTotal };
  }


}
