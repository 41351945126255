// Componentes
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable'

//Modelos e Interfaces
import { ICliente } from 'src/app/interfaces/cliente';

// Servicios
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { AvisosService } from 'src/app/services/datos/avisos.service';

// Entrorno y Librerias
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

interface Editing {
  [key: string]: boolean;
}

@Component({
  selector: 'app-clientes-lista',
  templateUrl: './clientes-lista.component.html',
  styleUrls: ['./clientes-lista.component.scss']
})
export class ClientesListaComponent implements OnInit {
  public fotoDir: string = environment.fotoDir;
  loading: boolean = false;

  textoDestacado: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }


  clientesList: ICliente[] = [];
  rows: any[] = [];
  temp: any[] = [];

  columns = [];
  ColumnMode = ColumnMode;

  editing: Editing = {};

  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  @ViewChild(ClientesListaComponent) table: ClientesListaComponent | any;

  constructor(
    public clienteSrv: ClientesService,
    private notificacionesSrv: NotificacionesService,
    private avisoCliSrv: AvisosService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.clienteSrv.getLista()
      .subscribe(resp => {
        this.loading = false;
        this.clientesList = resp.data;
        this.rows = this.clientesList;
        this.temp = [...this.clientesList];

        console.log('__clientesList', this.clientesList);
      })
  }


  filtro(val: string, campo: string) {

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d[campo]) return d[campo].toLowerCase().indexOf(val) !== -1 || !val;
      return !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.clientesList;
  }


  buscar(event: any, campo: string) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.filtro(txt, campo);
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.clientesList;
  }

  resaltarTexto(value: string, textoDestacado: string) {
    if (!textoDestacado) {
      return value;
    }
    return value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>');
  }


  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }

  //  CRUD  // 

  deleteRow(row: any) {
    Swal.fire({
      title: '¿Borrar cliente?',
      icon: 'question',
      html: `Eliminar <b>${row.nombre}</b>`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.clienteSrv.deleteCli(row.id)
          .subscribe(resp => {
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
            } else {
              this.notificacionesSrv.aviso('info', `Registro eliminado`);
              this.rows = this.rows.filter((objeto) => objeto.id !== row.id);
            }
          })
      }
    })


  }


  updateValue(event: any, cell: string, rowIndex: number) {
    console.log('inline editing rowIndex', rowIndex);

    this.editing[rowIndex + '-' + cell] = false;

    const newValue = event.target.value;
    const oldValue = this.rows[rowIndex][cell];

    this.rows[rowIndex][cell] = newValue;
    this.rows = [...this.rows];

    this.clienteSrv.actualizaCli(this.rows[rowIndex])
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.rows[rowIndex][cell] = oldValue;
          this.notificacionesSrv.aviso('error', resp.mensaje);
        } else {
          this.notificacionesSrv.aviso('success', `Datos del cliente actualizados`);
        }

      })

  }

  async modalAvisoCli(cliente: ICliente) {

    console.log('__craAvisoCli()', cliente);

    const mensaje = await Swal.fire({
      title: `Avisar a ${cliente.nombre}`,
      html: `<span style="font-size: smaller;"> <i class="fa-solid fa-network-wired"></i> Recibirá el aviso mediante la intranet del cliente </span>`,
      input: 'textarea',
      inputPlaceholder: 'Máximo 255 caracteres...',
      inputAttributes: {
        maxlength: '255'
      },
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return 'Debes entrar algún texto';
        }
        if (value.length > 255) {
          return 'El texto no puede exceder los 255 caracteres';
        }
        return null;
      }
    });

    if (mensaje.isConfirmed && mensaje.value) {
      // Llamar al servicio con el texto value

      const data = {
        'id_cliente': cliente.id,
        'tipo': '1',
        'buscar_pedido': null,
        'mensaje': mensaje.value
      }

      this.loading = true
      this.avisoCliSrv.avisoNew(data).subscribe(resp => {
        this.loading = false
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje)
          return
        }
        console.log(resp)
        this.notificacionesSrv.aviso('success', resp.mensaje)
      })
    }
  }




  ///   AUX   ///

  // Exportar a Excel
  exportToExcel(): void {
    const fileName = `clientes-${new Date().toISOString()}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();

    // Forzar valores numéricos en las columnas Xlsx
    Object.entries(worksheet).forEach(([cell, cellObj]) => {
      if (!cell.startsWith('!') && !isNaN(cellObj.v) && cellObj.v !== '') {
        cellObj.v = Number(cellObj.v);
        cellObj.t = 'n';
      }
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);

  }


  openIntranetCliente() {
    window.open(environment.url, '_blank');
  }


}
