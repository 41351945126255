import * as Constantes from '../../shared/constants';

import { OnDestroy } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode } from '@swimlane/ngx-datatable'

//Modelos e Interfaces 
import { ICliente } from 'src/app/interfaces/cliente';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';

// Servicios 
import { ArticulosService } from 'src/app/services/datos/articulos.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';


// Librerias
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { ITraza } from 'src/app/interfaces/traza';



@Component({
  selector: 'app-traza-lista',
  templateUrl: './traza-lista.component.html',
  styleUrls: ['./traza-lista.component.scss']
})
export class TrazaListaComponent implements OnInit, OnDestroy {

  // Usar constantes en el template
  Constantes = Constantes

  loading: boolean = false;
  public fotoDir: string = environment.fotoDir;

  textoDestacado: string = '';
  public strBuscarTodo: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  trazaList: ITraza[] = [];
  clientesList: ICliente[] = [];
  campanyasList: string[] = [];

  rows: ITraza[] = [];
  temp: any[] = [];

  columns = [];
  ColumnMode = ColumnMode;

  opcionesList: IListaOpciones = {}
  cliSeleccionado: string = '';
  campanyaSeleccionada: string = '';

  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';


  @ViewChild(TrazaListaComponent) table: TrazaListaComponent | any;

  constructor(
    public articulosSrv: ArticulosService,
    private notificacionesSrv: NotificacionesService,
    private clientesSrv: ClientesService
  ) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.getClientesList();
    this.getCampanyasList();
    this.getTrazaList();
  }

  getTrazaList() {
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);
    this.opcionesList.camp_cod = this.campanyaSeleccionada;
    this.opcionesList.swLote = '1';

    this.loading = true;
    this.articulosSrv.getTrazaList(this.opcionesList).subscribe(resp => {
      this.loading = false;

      console.log('__getTrazaList() opcionesList', this.opcionesList);
      console.log('__getTrazaList() resp', resp);
      
      this.trazaList = resp.data;
      this.rows = this.trazaList;
      this.temp = [...this.trazaList];
    })
  }

  getClientesList() {
    this.loading = true;
    this.clientesSrv.getLista().subscribe(resp => {
      this.loading = false;
      this.clientesList = resp.data;
    })
  }

  getCampanyasList() {
    this.loading = true;
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);
    this.opcionesList.swLote = '1';
    this.articulosSrv.campanyasTrazaList(this.opcionesList).subscribe(resp => {
      this.loading = false;
      this.campanyasList = resp.data;
    })
  }

  // Búscar y filtrar

  filtro(val: string, campo: string) {

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d[campo]) return d[campo].toLowerCase().indexOf(val) !== -1 || !val;
      return !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.trazaList;
  }

  buscar(event: any, campo: string) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.filtro(txt, campo);
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.trazaList;
  }



  dblclickCampo(valor: any) {
    this.strBuscarTodo = String(valor);
    const txt = valor.toLowerCase() ?? '';
    this.buscaGlobal(txt);
  }


  buscaGlobal(txt: string) {
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.trazaList;
  }


  resaltarTexto(value: string | null, textoDestacado: string) {
    return value ? value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>') : '';
  }

  limpiaBusca() {
    this.strBuscarTodo = '';
    this.buscaGlobal('');
    const buscaTodoInput = document.getElementById('buscaTodo');
    buscaTodoInput?.focus();
  }

  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr);
  }


  // Filtros

  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
    this.campanyaSeleccionada = '';
    this.getCampanyasList();
    this.getTrazaList();
  }

  onCampanyaSelect(camp_cod: string) {
    this.campanyaSeleccionada = camp_cod;
    this.getTrazaList();
  }




  // AUX  //  


  // EXPORTAR A EXCEL
  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'trazabilidad_lotes.xlsx');
  }


}
