
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Interfaces / Modelos
import { ICliente } from 'src/app/interfaces/cliente';
import { IAlmacen } from 'src/app/interfaces/almacenes';
import { IUsuario } from 'src/app/interfaces/usuario';


// Servicios
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { AlmacenesService } from './../../../../services/datos/almacenes.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { AuxDataService } from 'src/app/services/datos/auxData.service';

// Libs
import CustomVal from 'src/app/providers/CustomValidators';
import FuncLib from 'src/app/providers/funcionesLib';
import { ModalImagenService } from 'src/app/services/modal-imagen.service';
import { environment } from 'src/environments/environment';
import { Subscription, delay } from 'rxjs';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-cliente-edit',
  templateUrl: './cliente-edit.component.html',
  styleUrls: ['./cliente-edit.component.scss']
})
export class ClienteEditComponent implements OnInit {

  cliente: ICliente = {}
  paramId: string = '';

  public fotoDir: string = environment.fotoDir;
  public imgSubs: Subscription | undefined;

  almacenesList: IAlmacen[] = [];
  public listaPob: any[] = [];

  public cliForm: FormGroup = new FormGroup({});

  public enviado: boolean = false;
  public loading: boolean = false;

  private funcLib: FuncLib = new FuncLib();
  public infoUbiDesde: string = '';
  public infoUbiHasta: string = '';

  ultimoAlmacenDesde: string = '';
  ultimoAlmacenHasta: string = '';
  public infoDesdeObj: any = {};
  public infoHastaObj: any = {};

  constructor(
    private clienteSrv: ClientesService,
    public usuarioSrv: UsuarioService,
    private almacenesSrv: AlmacenesService,
    private auxDataSrv: AuxDataService,
    public modalImagenSrv: ModalImagenService,
    private notificacionesService: NotificacionesService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  public serviciosList: any[] = [];
  public codServicios_posibles: any[] = [];
  public codServicios_seleccionados: any[] = [];


  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos del cliente
    this.activatedRoute.params.subscribe(({ id }) => {
      this.cliente.foto = '_noFoto.png';
      this.paramId = id;
      if (this.paramId != 'new') this.cargarCliente(id);
    })

    this.declaraForm();

    // Suscribirse a los cambios en el campo almacen_desde
    this.cliForm.get('almacen_desde')?.valueChanges.subscribe(value => {
      if (typeof value === 'string' && value.length >= 2) {
        const nuevoAlmacen = value.substring(0, 2);
        if (this.ultimoAlmacenDesde !== nuevoAlmacen) {
          this.ultimoAlmacenDesde = nuevoAlmacen;
          this.onCambioAlmacenDesde(nuevoAlmacen);
        }
      }
      if (!value) this.infoDesdeObj = {};
    });

    // Suscribirse a los cambios en el campo almacen_hasta
    this.cliForm.get('almacen_hasta')?.valueChanges.subscribe(value => {
      if (typeof value === 'string' && value.length >= 2) {
        const nuevoAlmacen = value.substring(0, 2);
        if (this.ultimoAlmacenHasta !== nuevoAlmacen) {
          this.ultimoAlmacenHasta = nuevoAlmacen;
          this.onCambioAlmacenHasta(nuevoAlmacen);
        }
      }
      if (!value) this.infoHastaObj = {};
    });

    this.getAlmacenesList();

    // Nos suscribimos a la imagen, 
    // Si cambia la imagen, buscamos esa id en this.lista[]

    this.imgSubs = this.modalImagenSrv.nuevaImagen
      .pipe(delay(100))
      .subscribe(img => {
        this.cliente.foto = img.guardado_como;
      });

  }

  declaraForm() {
    // Declara el fom reactivo
    this.cliForm = this.fb.group({
      nombre: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(150)]],
      contacto: [''],
      conta_campanya: [''],
      direccion: ['', [Validators.minLength(6), Validators.maxLength(150)]],
      cp: [''],
      poblacion: ['', Validators.required],
      provincia: [''],
      email: ['', [Validators.required, CustomVal.ValidateEmail]],
      telefono: [''],
      url: ['', CustomVal.urlValidator],
      alm_por_defecto: ['', Validators.required],
      almacen_desde: ['', [Validators.minLength(12), Validators.maxLength(12)]],
      almacen_hasta: ['', [Validators.minLength(12), Validators.maxLength(12)]],
      lifo_fifo: [''],
      prn_packing_list: ['S', Validators.required],

      conf_url_pedidos: [0],
      conf_url_pedidos_str: [''],
    })
  }

  cargarCliente(id: number) {
    this.loading = true;
    this.clienteSrv.getCliente(id)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesService.aviso('error', resp.mensaje);
          this, this.usuarioSrv.logOut();
        } else {
          this.cliente = resp.data;

          console.log('__cargarCliente() resp', resp);

          this.cliForm.patchValue({
            conta_campanya: this.cliente.conta_campanya,
            nombre: this.cliente.nombre,
            contacto: this.cliente.contacto,
            direccion: this.cliente.direccion,
            cp: this.cliente.cp,
            poblacion: this.cliente.poblacion,
            provincia: this.cliente.provincia,
            telefono: this.cliente.telefono,
            email: this.cliente.email,
            url: this.cliente.url,
            alm_por_defecto: this.cliente.alm_por_defecto,
            almacen_desde: this.cliente.almacen_desde,
            almacen_hasta: this.cliente.almacen_hasta,
            lifo_fifo: this.cliente.lifo_fifo,
            prn_packing_list: this.cliente.prn_packing_list,

            conf_url_pedidos: this.cliente.conf_url_pedidos,
            conf_url_pedidos_str: this.cliente.conf_url_pedidos_str
          })


          this.buscaPob();
          this.cambiaUbiDesde();
          this.cambiaUbiHasta();

        }
      })
  }


  // Get lista de Almacenes
  getAlmacenesList() {
    this.loading = true;
    this.almacenesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.almacenesList = respuesta.data;
      })
  }


  enviar() {
    if (this.paramId === 'new') {
      this.newCli();
    } else {
      this.actualizaCli();
    }
    return
  }


  newCli() {
    this.enviado = true;

    if (this.cliForm.invalid) {
      console.log("ERRORES:", this.cliForm);
      return;
    }
    // Todo ok, enviar al BackEnd

    this.loading = true;
    const data = this.cliForm.value

    console.log('__newCli() data=', data)

    this.clienteSrv.newCli(data)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.cliForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesService.aviso('success', `Cliente ${resp.data.nombre} creado correctamente con el <b>id: ${resp.data.id}</b>`);
          this.router.navigateByUrl(`panel/clientes_lista`);
        }
      })
  }

  actualizaCli() {
    this.enviado = true;

    if (this.cliForm.invalid) {
      console.log("ERRORES:", this.cliForm);
      return;
    }

    // Todo ok, enviar al BackEnd  
    const data = {
      ...this.cliForm.value,
      id: this.cliente.id,
    };

    console.log('__actualizaCli() data=', data)

    this.loading = true;
    this.clienteSrv.actualizaCli(data)
      .subscribe(resp => {
        this.loading = false;

        console.log('__actualizaCli()', resp)

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log("errores desde la API:", resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.cliForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesService.aviso('success', `Cliente ${this.cliForm.get('nombre')?.value} actualizado correctamente `);
          this.router.navigateByUrl(`panel/clientes_lista`);
        }
      })
  }

  almacenChange() {
    // Cambia el almacén, limpiar posibles errores de ubicaciones
    this.limpiaErroresUbiDesde();
    this.limpiaErroresUbiHasta();
  }

  cambiaUbiDesde() {
    const ubicacion = this.cliForm.get('almacen_desde')?.value;
    this.infoUbiDesde = this.funcLib.CodUbiDecript(ubicacion);
    this.limpiaErroresUbiDesde();
    this.limpiaErroresUbiHasta();
  }

  cambiaUbiHasta() {
    const ubicacion = this.cliForm.get('almacen_hasta')?.value;
    this.infoUbiHasta = this.funcLib.CodUbiDecript(ubicacion);
    this.limpiaErroresUbiDesde();
    this.limpiaErroresUbiHasta();
  }

  // Limpiar errores de ubicaciones
  limpiaErroresUbiDesde() {
    const almacenDesdeControl = this.cliForm.get('almacen_desde');
    if (almacenDesdeControl) {
      almacenDesdeControl.setErrors(null); // Elimina los errores
      almacenDesdeControl.markAsPristine(); // Marca como limpioF
      almacenDesdeControl.markAsUntouched(); // Marca como no tocado
    }
  }

  limpiaErroresUbiHasta() {
    const almacenHastaControl = this.cliForm.get('almacen_hasta');
    if (almacenHastaControl) {
      almacenHastaControl.setErrors(null); // Elimina los errores
      almacenHastaControl.markAsPristine(); // Marca como limpio
      almacenHastaControl.markAsUntouched(); // Marca como no tocado
    }
  }



  get f() {
    return this.cliForm.controls;
  }



  ///   AUX   ///

  // Cambia el almacen de una ubicación
  onCambioAlmacenDesde(nuevoAlm: string) {
    console.log('Las 2 primeras posiciones de almacen_desde han cambiado:', nuevoAlm);
    this.almacenesSrv.detallCod(nuevoAlm).subscribe(resp => {
      console.log('__onCambioAlmacenDesde', resp);
      this.infoDesdeObj = resp.data;
    })
  }

  onCambioAlmacenHasta(nuevoAlm: string) {
    this.almacenesSrv.detallCod(nuevoAlm).subscribe(resp => {
      this.infoHastaObj = resp.data;
    })
  }


  buscaPob() {
    var cp = this.cliForm.value.cp;
    this.cliForm.patchValue({ poblacion: '', provincia: '' });
    this.listaPob = [];

    if (cp.length < 5) return;

    this.loading = true;
    this.auxDataSrv.getPoblaciones(cp)
      .subscribe((resp) => {
        this.loading = false;
        if (resp.error) {
          this.cliForm.controls['cp'].setErrors({ 'noExiste': true });
          return;
        }

        // Lista de Poblaciones
        var pobAux: string = '';
        for (let key of Object.keys(resp.data)) {
          if (typeof resp.data[key] === 'object' && resp.data[key].hasOwnProperty('Poblacion')) {
            pobAux = resp.data[key]['Poblacion'];
            if (!this.listaPob.includes(pobAux)) this.listaPob.push(pobAux);
          }
        }
        this.cliForm.patchValue({ poblacion: this.listaPob[0] });
        if (this.listaPob.includes(this.cliente.poblacion)) this.cliForm.patchValue({ poblacion: this.cliente.poblacion });

        // Provincia
        this.cliForm.patchValue({ provincia: resp.data.provincia.provincia });

      });

  }

  abrirModal(cliente: ICliente) {
    this.modalImagenSrv.abrirModal('clientes', cliente.id!.toString(), cliente.foto);
  }

}
