<div class="row">
    <div class="col-12 animated fadeIn fast">
        <div class="card border border-info rounded">
            <div class="card-body ">

                <form (ngSubmit)="enviar()">
                    <div class="row">

                        <!-- cliSeleccionado -->
                        <div class="col-4">
                            <div class="input-group border border-dark rounded"
                                 [ngClass]="{ 'has-danger': errores.cliSeleccionado }">
                                <div class="input-group-addon">Cliente</div>
                                <select class="form-control"
                                        [(ngModel)]="cliSeleccionado"
                                        name="cliSeleccionado"
                                        (ngModelChange)="onClienteSelect($event)">
                                    <option value=''>Selecciona...</option>
                                    <option *ngFor="let cli of clientesList"
                                            [value]=cli.id>
                                        {{cli.nombre}}
                                    </option>
                                </select>
                            </div>

                            <div *ngIf="enviado && errores.cliSeleccionado"
                                 class="form-control-feedback">
                                <span class="text-danger">{{ errores.cliSeleccionado }}</span>
                            </div>
                        </div>


                        <!-- fechaDesde -->
                        <div class="col-3">
                            <div class="input-group border border-dark rounded"
                                 [ngClass]="{ 'has-danger': errores.fechaDesde }">
                                <div class="input-group-addon">Desde</div>
                                <input class="form-control"
                                       type="date"
                                       [(ngModel)]="fechaDesde"
                                       name="fechaDesde"
                                       required>
                            </div>
                            <div *ngIf="enviado && errores.fechaDesde"
                                 class="form-control-feedback">
                                <span class="text-danger">{{ errores.fechaDesde }}</span>
                            </div>
                        </div>


                        <!-- fechaHasta -->
                        <div class="col-3">
                            <div class="input-group border border-dark rounded"
                                 [ngClass]="{ 'has-danger': errores.fechaHasta }">
                                <div class="input-group-addon">Hasta</div>
                                <input class="form-control"
                                       type="date"
                                       [(ngModel)]="fechaHasta"
                                       name="fechaHasta"
                                       required>
                            </div>
                            <div *ngIf="enviado && errores.fechaHasta"
                                 class="form-control-feedback">
                                <span class="text-danger">{{ errores.fechaHasta }}</span>
                            </div>
                        </div>


                        <!-- Submit -->
                        <div class="col-2">
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-2 btn waves-effect waves-light btn-rounded btn-success">
                                <i class="fa fa-spinner fa-spin"
                                   *ngIf="loading"></i> Enviar</button>
                        </div>

                    </div><!-- row -->
                </form>

            </div>
        </div>
    </div>
</div> <!-- row -->



<div class="row">
    <div *ngIf="rows.length > 0"
         class="col-12 animated fadeIn fast">

        <div class="card border border-info rounded">
            <div class="card-body ">



                <!-- cabecera -->
                <div class="row mb-2">

                    <div class="col-4">
                        <div class="subTitulo text-info">
                            {{subTitulo}}
                        </div>
                    </div>

                    <div class="col-4">
                        <h2>
                            <small>
                                <a href="javascript:void(0)"
                                   (click)="table.rowDetail.expandAllRows()">Expandir Todo</a> |
                                <a href="javascript:void(0)"
                                   (click)="table.rowDetail.collapseAllRows()">Colapsar Todo</a>
                            </small>
                        </h2>
                    </div>
                    
                    <div class="col-2 text-right">
                        <span *ngIf="swEnStock==='1'">
                            <button [disabled]="loading"
                                    class="ml-2 btn waves-effect waves-light btn-rounded btn-secondary"
                                    (click)="confirmarTodo()">
                                <i class="fa fa-spinner fa-spin"
                                   *ngIf="loading"></i> Confirmar todo
                            </button>
                        </span>
                    </div>

                    <div class="col-2">

                        <form>

                            <div class="row float-right">

                                <div class="col-sm-6">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group float-right">
                                        <div class="btn-group">

                                            <button type="button"
                                                    [ngClass]="{'disabled': loading}"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                </div>




                <ngx-datatable #myTable
                               class="bootstrap expandable"
                               [columnMode]="ColumnMode.force"
                               [limit]="rowsLimit"
                               [headerHeight]="50"
                               [rowHeight]="50"
                               [scrollbarV]="false"
                               [rows]="rows"
                               (page)="onPage($event)"
                               (rowClick)="toggleExpandRow(rows)"
                               [footerHeight]="50"
                               [summaryRow]="true"
                               [summaryHeight]="55"
                               [summaryPosition]="summaryPosition">



                    <!-- Row Detail Template -->
                    <ngx-datatable-row-detail [rowHeight]="100"
                                              #myDetailRow
                                              (toggle)="onDetailToggle($event)">
                        <ng-template let-row="row"
                                     let-expanded="expanded"
                                     ngx-datatable-row-detail-template>

                            <div class="bootstrap-table table-bordered border-info  "
                                 style="margin-left:35px;margin-right:35px; margin-bottom: 5px;">
                                <div class="table-responsive">
                                    <table class="table table-sm table-hover">

                                        <thead>
                                            <tr>
                                                <th>Palé</th>
                                                <th>Sku</th>
                                                <th>Artículo</th>
                                                <th>Lote</th>
                                                <th>Fec. caducidad</th>
                                                <th>Cantidad</th>
                                                <th>Ubicación</th>
                                            </tr>
                                        </thead>

                                        <!-- Si hay detall, mostrarlo -->
                                        <tbody *ngIf="row?.detall">
                                            <ng-container *ngFor="let lin of row.detall; let i = index">
                                                <tr>
                                                    <td>{{ lin.pale }}</td>
                                                    <td>{{ lin.artSku }}
                                                        <span *ngIf="lin.swDefectuoso == Constantes.DEFECTUOSO"
                                                              class="text-warning ml-12">
                                                            <i title="Defectuoso"
                                                               class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                                        </span>

                                                    </td>
                                                    <td>{{limitarYAgregarPuntos( lin.artDescripcion, 30) }}</td>
                                                    <td>{{ lin.lote }}</td>
                                                    <td>
                                                        <span [innerHTML]="lin.fecha_caducidad | iconFechaCaducada"></span>
                                                    </td>

                                                    <td style="text-align: right; padding-right: 10px">{{ lin.cantidad | number: '0.0-0':'es' }}</td>
                                                    <td>{{ lin.ubicacion! | mask:'00.00.00.00.00.00' }}</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-row-detail>


                    <!-- Column Templates -->
                    <ngx-datatable-column [width]="50"
                                          [resizeable]="false"
                                          [sortable]="false"
                                          [draggable]="false"
                                          [canAutoResize]="false">
                        <ng-template let-row="row"
                                     let-expanded="expanded"
                                     ngx-datatable-cell-template>
                            <a href="javascript:void(0)"
                               [class.datatable-icon-right]="!expanded"
                               [class.datatable-icon-down]="expanded"
                               title="Expand/Collapse Row"
                               (click)="toggleExpandRow(row)">
                            </a>
                        </ng-template>
                    </ngx-datatable-column>


                    <ngx-datatable-column name="Index"
                                          [width]="80">
                        <ng-template let-rowIndex="rowIndex"
                                     let-row="row"
                                     ngx-datatable-cell-template>
                            <strong>{{ rowIndex }}</strong>
                        </ng-template>
                    </ngx-datatable-column>



                    <!-- almNombre -->
                    <ngx-datatable-column name='Almacén'
                                          prop='almNombre'
                                          [width]="160">
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <strong>{{ value }}</strong>
                        </ng-template>
                    </ngx-datatable-column>


                    <!-- created_at -->
                    <ngx-datatable-column name='Fecha Entrada'
                                          prop='created_at'
                                          [width]="150">
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <strong>{{ value | date: 'dd/MM/yyyy'}}</strong>
                        </ng-template>
                    </ngx-datatable-column>


                    <!-- devolucion -->
                    <ngx-datatable-column name="Devolucion"
                                          prop='devolucion'
                                          [width]="100">
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <div title="Entrada por devolución"
                                 [innerHTML]="value | iconCheck"></div>
                        </ng-template>
                    </ngx-datatable-column>


                    <!-- cliNombre -->
                    <ngx-datatable-column name='Cliente'
                                          prop='cliNombre'>
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <strong>{{ value }}</strong>
                        </ng-template>
                    </ngx-datatable-column>


                    <!-- albaran -->
                    <ngx-datatable-column name='Albarán'
                                          prop='albaran'>
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <strong>{{ value }}</strong>
                        </ng-template>
                    </ngx-datatable-column>



                    <!-- pales -->
                    <ngx-datatable-column name='Palés'
                                          prop='pales'
                                          [width]="100"
                                          [cellClass]="'text-right monospace'"
                                          [summaryFunc]="sumaCeldas">
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <strong>{{ value }}</strong>
                        </ng-template>
                    </ngx-datatable-column>


                    <!-- proveedor -->
                    <!-- <ngx-datatable-column name='Proveedor'
                                          prop='proveedor'
                                          [width]="200">
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <strong>{{ value }}</strong>
                        </ng-template>
                    </ngx-datatable-column> -->


                    <!-- username -->
                    <ngx-datatable-column name='Usuario'
                                          prop='username'
                                          [width]="100">
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <strong>{{ value }}</strong>
                        </ng-template>
                    </ngx-datatable-column>


                    <!-- datos de Facturación -->
                    <!-- fac_pales -->
                    <ngx-datatable-column name='Palés fact.'
                                          prop='fac_pales'
                                          [width]="100"
                                          [cellClass]="'text-right monospace text-primary'"
                                          [headerClass]="'text-primary'"
                                          [summaryFunc]="sumaCeldas">
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <strong>{{ value }}</strong>
                        </ng-template>
                    </ngx-datatable-column>

                    <!-- fac_horas -->
                    <ngx-datatable-column name='Horas Fact.'
                                          prop='fac_horas'
                                          [width]="100"
                                          [cellClass]="'text-right monospace text-primary'"
                                          [headerClass]="'text-primary'"
                                          [summaryFunc]="sumaCeldas">
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <strong>{{ value | number: '1.2-2':'es' }}</strong>
                        </ng-template>
                    </ngx-datatable-column>
                    <!-- fac_etiquetas -->
                    <ngx-datatable-column name='Etiq. Fact.'
                                          prop='fac_etiquetas'
                                          [width]="100"
                                          [cellClass]="'text-right monospace text-primary'"
                                          [headerClass]="'text-primary'"
                                          [summaryFunc]="sumaCeldas">
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <strong>{{ value | number: '0.0-0':'es' }}</strong>
                        </ng-template>
                    </ngx-datatable-column>




                    <!-- fac_contenedor20 -->
                    <ngx-datatable-column name="Cont. 20'"
                                          prop='fac_contenedor20'
                                          [width]="100"
                                          [cellClass]="'text-right monospace text-primary'"
                                          [headerClass]="'text-primary'"
                                          [summaryFunc]="sumaCeldas">
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <strong>{{ value }}</strong>
                        </ng-template>
                    </ngx-datatable-column>


                    <!-- fac_contenedor40 -->
                    <ngx-datatable-column name="Cont. 40'"
                                          prop='fac_contenedor40'
                                          [width]="100"
                                          [cellClass]="'text-right monospace text-primary'"
                                          [headerClass]="'text-primary'"
                                          [summaryFunc]="sumaCeldas">
                        <ng-template let-value="value"
                                     ngx-datatable-cell-template>
                            <strong>{{ value }}</strong>
                        </ng-template>
                    </ngx-datatable-column>



                    <!-- Acciones -->
                    <ngx-datatable-column name="Acciones"
                                          [width]="120"
                                          [sortable]="false"
                                          [resizeable]="false">

                        <ng-template let-row="row"
                                     let-value="value"
                                     ngx-datatable-cell-template>

                            <a (click)="creaPdf(row)"
                               class="link mr-2 pointer"
                               data-toggle="tooltip"
                               title="Crear un PDF con esta entrada">
                                <span class="badge"
                                      [ngClass]="{'disabled': loading}">P</span>
                            </a>

                            <a (click)="modalFacturacion(row)"
                               class="link mr-2 pointer"
                               data-toggle="tooltip"
                               title="Editar datos de FACTURACIÓN">
                                <span class="badge"
                                      [ngClass]="{'disabled': loading}">F</span>
                            </a>

                            <span *ngIf="swEnStock==='1'">
                                <a (click)="confirmaFinalizar(row)"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="CONFIRMAR entrada física en stock">
                                    <span class="badge"
                                          [ngClass]="{'disabled': loading}">C</span>
                                </a>
                            </span>



                        </ng-template>

                    </ngx-datatable-column>







                </ngx-datatable>

            </div>
        </div>
    </div>
</div> <!-- row -->


<!-- modal para facturación de entradas -->
<app-modal-fact-entrada></app-modal-fact-entrada>