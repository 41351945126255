<div class="row animated fadeIn fast">

    <div class="col-lg-12">
        <div class="card">

            <div class="card-body">
                <form class="form p-t-20"
                      [formGroup]="cliForm"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">

                            <!-- nombre -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['nombre'].errors }">
                                    <label class="form-control-label">Nombre:</label>
                                    <input type="text"
                                           formControlName="nombre"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['nombre'].errors }">

                                    <div *ngIf="enviado && f['nombre'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['nombre'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['nombre'].errors['minlength']">Debe contener un mínimo de 4 caracteres</div>
                                        <div *ngIf="f['nombre'].errors['maxlength']">Nombre no puede exceder de 150 caracteres</div>
                                        <div *ngIf="cliForm.get('nombre')?.errors?.['apiError']">{{cliForm.get('nombre')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>




                            <!-- contacto -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['contacto'].errors }">
                                    <label class="form-control-label">Persona de contacto:</label>
                                    <input type="text"
                                           formControlName="contacto"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['nombre'].errors }">

                                    <div *ngIf="enviado && f['contacto'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['contacto'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['contacto'].errors['minlength']">Debe contener un mínimo de 4 caracteres</div>
                                        <div *ngIf="f['contacto'].errors['maxlength']">Nombre no puede exceder de 150 caracteres</div>
                                        <div *ngIf="cliForm.get('contacto')?.errors?.['apiError']">{{cliForm.get('contacto')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>


                        </div> <!-- row -->


                        <div class="row">

                            <!-- direccion -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['direccion'].errors }">
                                    <label class="form-control-label">Dirección:</label>
                                    <input type="text"
                                           formControlName="direccion"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['direccion'].errors }">

                                    <div *ngIf="enviado && f['direccion'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['direccion'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['direccion'].errors['minlength']">Como mínimo 6 caracteres</div>
                                        <div *ngIf="f['direccion'].errors['maxlength']">Como máximo 150 caracteres</div>
                                        <div *ngIf="cliForm.get('direccion')?.errors?.['apiError']">{{cliForm.get('direccion')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>



                            <!-- cp -->
                            <div class="form-group col-md-1">
                                <div class="form-floating mb-3">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger':   f['cp'].errors }">
                                        <label class="form-control-label">cp:</label>
                                        <input formControlName="cp"
                                               class="form-control"
                                               [ngClass]="{ 'form-control-danger': f['cp'].errors }"
                                               maxlength="5"
                                               (keyup)="buscaPob()" />

                                        <div *ngIf="f['cp'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['cp'].errors['noExiste']">No existe</div>
                                            <div *ngIf="cliForm.get('cp')?.errors?.['apiError']">{{cliForm.get('cp')?.errors?.['apiError'] }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- poblacion -->
                            <div class="form-group col-md-3">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['poblacion'].errors }">
                                    <label class="form-control-label">Población:</label>
                                    <select formControlName="poblacion"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': enviado && f['poblacion'].errors }">
                                        <option *ngFor="let pob of listaPob"
                                                [value]="pob">
                                            {{pob}}
                                        </option>
                                    </select>
                                    <div *ngIf="enviado && f['poblacion'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="cliForm.get('poblacion')?.errors?.['apiError']">{{
                                            cliForm.get('poblacion')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>

                            </div>

                            <!-- provincia -->
                            <div class="form-group col-md-2">
                                <label for="strProOri">Provincia:</label>
                                <input class="form-control readonly"
                                       formControlName="provincia"
                                       readonly>
                            </div>



                        </div> <!-- row -->


                        <div class="row">


                            <div class="col-md-3">
                                <div class="form-group">
                                    <!-- foto -->
                                    <span *ngIf="cliente.id">

                                        <label class="form-control-label ml-4"
                                               data-toggle="tooltip"
                                               title="Banner qe se usará en los pdf de los ALBARANES">
                                            Banner: (1000 x 300)
                                            <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                        </label>

                                        <div class="text-center shadow mt4">
                                            <img [src]="fotoDir +'/clientes/'+ cliente.foto"
                                                 loading="lazy"
                                                 (click)="abrirModal(cliente)"
                                                 alt="Banner"
                                                 title="Modificar"
                                                 class="img-thumbnail img-avatar pointer mt-1"
                                                 height="300px">
                                        </div>
                                    </span>
                                </div>
                            </div>

                            <!-- email -->
                            <div class="col-md-3">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['email'].errors }">
                                    <label class="form-control-label">Email:</label>
                                    <input type="text"
                                           formControlName="email"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['email'].errors }">

                                    <div *ngIf="enviado && f['email'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['email'].errors['required']">Obligatorio</div>
                                        <div *ngIf="f['email'].errors['eMailFormat']">No parece un eMail válido</div>
                                        <div *ngIf="cliForm.get('email')?.errors?.['apiError']">{{cliForm.get('email')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>


                            <!-- telefono -->
                            <div class="col-md-2">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['telefono'].errors }">
                                    <label class="form-control-label">Teléfono:</label>
                                    <input type="text"
                                           formControlName="telefono"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['telefono'].errors }">
                                    <div *ngIf="enviado && f['telefono'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['telefono'].errors['required']">Obligatorio</div>
                                        <div *ngIf="cliForm.get('telefono')?.errors?.['apiError']">{{cliForm.get('telefono')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>


                            <!-- url -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['url'].errors }">
                                    <label class="form-control-label">Web:</label>
                                    <input type="text"
                                           formControlName="url"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['url'].errors }">

                                    <div *ngIf="enviado && f['url'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['url'].errors['urlValid']">No parece una dirección url válida</div>
                                        <div *ngIf="cliForm.get('url')?.errors?.['apiError']">{{cliForm.get('url')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>


                        </div> <!-- row -->


                        <div class="row">
                            <div class="col-12">
                                <small class="text-info mb-0">Almacén</small>
                                <hr class="text-info mt-0 mb-3">
                            </div>
                        </div>



                        <div class="row">



                            <!-- alm_por_defecto -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['alm_por_defecto'].errors }">
                                    <label class="form-control-label">Almacen predeterminado</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"> <i class="fa-solid fa-warehouse"></i></div>

                                        <select formControlName="alm_por_defecto"
                                                class="form-control"
                                                [ngClass]="{ 'form-control-danger': enviado && f['alm_por_defecto'].errors }"
                                                (change)="almacenChange()">
                                            <option value="">Selecciona...</option>
                                            <option *ngFor="let alm of almacenesList"
                                                    [value]="alm.id">
                                                {{alm.nombre}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="enviado && f['alm_por_defecto'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="cliForm.get('alm_por_defecto')?.errors?.['apiError']">{{
                                            cliForm.get('alm_por_defecto')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>



                            <!-- almacen_desde -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['almacen_desde'].errors }">
                                    <label class="form-control-label">Reservar espacio desde ubicación:</label>
                                    <input type="text"
                                           mask="00.00.00.00.00.00"
                                           (input)="cambiaUbiDesde()"
                                           formControlName="almacen_desde"
                                           class="form-control grande"
                                           [ngClass]="{ 'form-control-danger': enviado && f['almacen_desde'].errors }">
                                    <div *ngIf="enviado && f['almacen_desde'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['almacen_desde'].errors['minlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['almacen_desde'].errors['maxlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['almacen_desde'].errors?.['apiError']">{{cliForm.get('almacen_desde')?.errors?.['apiError']}}</div>
                                    </div>
                                    <small>{{infoUbiDesde}}</small><br>
                                    <small class="text-secondary">{{infoDesdeObj.nombre }}</small>
                                </div>
                            </div>

                            <!-- almacen_hasta -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['almacen_hasta'].errors }">
                                    <label class="form-control-label">Reservar espacio hasta ubicación:</label>
                                    <input type="text"
                                           (input)="cambiaUbiHasta()"
                                           mask="00.00.00.00.00.00"
                                           formControlName="almacen_hasta"
                                           class="form-control grande"
                                           [ngClass]="{ 'form-control-danger': enviado && f['almacen_hasta'].errors }">
                                    <div *ngIf="enviado && f['almacen_hasta'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="f['almacen_hasta'].errors['minlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['almacen_hasta'].errors['maxlength']">Deben ser 12 digitos</div>
                                        <div *ngIf="f['almacen_hasta'].errors?.['apiError']">{{cliForm.get('almacen_hasta')?.errors?.['apiError']}}</div>
                                    </div>
                                    <small>{{infoUbiHasta}}</small><br>
                                    <small class="text-secondary">{{infoHastaObj.nombre }}</small>
                                </div>
                            </div>


                        </div> <!-- row -->


                        <div class="row">
                            <div class="col-12">
                                <small class="text-info mb-0">Pedidos</small>
                                <hr class="text-info mt-0 mb-3">
                            </div>
                        </div>


                        <div class="row">


                            <!-- conta_campanya -->
                            <div class="form-group col-md-4">
                                <label for="strProOri">Última campaña:</label>
                                <input class="form-control readonly"
                                       formControlName="conta_campanya"
                                       readonly>
                            </div>


                            <!-- lifo_fifo -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['lifo_fifo'].errors }">
                                    <label class="form-control-label">LiFo/FiFo:</label>
                                    <select formControlName="lifo_fifo"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': enviado && f['lifo_fifo'].errors }">
                                        <option [value]="'lifo'">LiFo</option>
                                        <option [value]="'fifo'">FiFo</option>
                                        <option [value]="''">Indiferente</option>
                                    </select>
                                    <div *ngIf="enviado && f['lifo_fifo'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="cliForm.get('lifo_fifo')?.errors?.['apiError']">{{
                                            cliForm.get('lifo_fifo')?.errors?.['apiError'] }}</div>
                                    </div>
                                    <small><i> Orden:</i>
                                        Lote-Fecha caducidad +
                                        FiFo o LiFo +
                                        Peso
                                    </small>
                                </div>
                            </div>




                            <!-- prn_packing_list -->
                            <div class="col-md-4">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['prn_packing_list'].errors }">
                                    <label class="form-control-label"
                                           data-toggle="tooltip"
                                           title="Se imprimirá el Packing List cada vez que se genere un envío de este cliente">
                                        Imprime el Packing List:
                                        <i class="ml-1 text-info fa-solid fa-circle-info"></i>
                                    </label>
                                    <select formControlName="prn_packing_list"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': enviado && f['prn_packing_list'].errors }">
                                        <option [value]="'S'">Si</option>
                                        <option [value]="'N'">No</option>
                                    </select>
                                    <div *ngIf="enviado && f['prn_packing_list'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="cliForm.get('prn_packing_list')?.errors?.['apiError']">{{
                                            cliForm.get('prn_packing_list')?.errors?.['apiError'] }}</div>
                                    </div>
                                </div>
                            </div>



                        </div> <!-- row -->




                        <legend class="ms-1 text-info">Configuradores:</legend>
                        <hr class="text-info mt-0 mb-3">
                        

                        <div class="card mt-2 border border-secondary rounded configCard">

                            <div class="card-title">
                                <h4 class="card-title p-2"> <i class="fa-solid fa-link"></i> Url externa</h4>
                            </div>

                            <div class="card-body mt-0">
                                <div class="row">

                                    <!-- conf_url_pedidos -->
                                    <div class="col-md-12">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['conf_url_pedidos'].errors }">
                                            <div class="custom-control custom-switch">
                                                <input formControlName="conf_url_pedidos"
                                                       type="checkbox"
                                                       class="custom-control-input"
                                                       id="conf_url_pedidos">
                                                <label class="custom-control-label"
                                                       for="conf_url_pedidos">Llamada a URL externa en cada envío</label>
                                            </div>
                                            <p>
                                                <small class="text-info mb-0 ml-2">Al generar un envío, debe hacer una llamada a una URL externa? (0=No/1=Si)</small>
                                            </p>
                                        </div>
                                    </div>


                                    <!-- conf_url_pedidos_str -->
                                    <div class="col-md-6"
                                         *ngIf="cliForm.get('conf_url_pedidos')?.value">
                                        <div class="form-group"
                                             [ngClass]="{ 'has-danger': enviado && f['conf_url_pedidos_str'].errors }">
                                            <label class="form-control-label">Url a llamar: (+ ref_envio)</label>
                                            <input type="text"
                                                   formControlName="conf_url_pedidos_str"
                                                   class="form-control"
                                                   [ngClass]="{ 'form-control-danger': enviado && f['email'].errors }">
                                            <div *ngIf="enviado && f['conf_url_pedidos_str'].errors"
                                                 class="form-control-feedback">
                                                <div *ngIf="cliForm.get('conf_url_pedidos_str')?.errors?.['apiError']">{{cliForm.get('conf_url_pedidos_str')?.errors?.['apiError'] }}</div>
                                            </div>
                                        </div>
                                    </div>


                                </div> <!-- row -->

                            </div> <!-- card-body  -->
                        </div> <!-- card -->



                        <hr class="my-4">

                        <div class="d-flex">
                            <button [disabled]="loading"
                                    type="button"
                                    class="btn waves-effect waves-light btn-rounded btn-secondary"
                                    [routerLink]="'/panel/clientes_lista'"
                                    data-dismiss="modal">Cancelar</button>
                            <h6 class="card-subtitle ml-4 ">
                                <li *ngIf="cliente.created_at"> Creado hace {{cliente.created_at! | friendlyDdmmyy}}</li>
                                <li *ngIf="cliente.updated_at"> Última modificación hace {{cliente.updated_at! | friendlyDdmmyy}}</li>
                            </h6>
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Enviar</button>
                        </div>

                    </div>

                </form>
            </div>
        </div>
    </div>

</div>