import * as Constantes from '../../../../shared/constants';

import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


//Modelos e Interfaces 
import { ICliente } from 'src/app/interfaces/cliente';
import { IFacturacion } from 'src/app/interfaces/facturacion';

// Servicios  
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { FacturacionService } from 'src/app/services/datos/facturacion.service';

// Librerias 
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-clientes-fac-lista',
  templateUrl: './clientes-fac-lista.component.html',
  styleUrls: ['./clientes-fac-lista.component.scss']
})
export class ClientesFacListaComponent implements OnInit {

  // Usar constantes en el template
  Constantes = Constantes
  loading: boolean = false;
  enviado: boolean = false;

  textoDestacado: string = '';
  public strBuscarTodo: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  facturasList: IFacturacion[] = [];
  clientesList: ICliente[] = [];

  rows: IFacturacion[] = [];
  temp: any[] = [];

  columns = [];
  ColumnMode = ColumnMode;

  opcionesList: any = {}
  cliSeleccionado: string = '';
  public fechaDesde: string = '';
  public fechaHasta: string = '';

  // Errores
  public errores: any = {}

  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  @ViewChild(ClientesFacListaComponent) table: ClientesFacListaComponent | any;

  constructor(
    private clientesSrv: ClientesService,
    private facturacionSrv: FacturacionService

  ) { }

  ngOnInit(): void {
    this.getListaCli();
    this.getListaFac();
  }

  getListaFac() {
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);

    this.loading = true;
    console.log('__getListaFac() opcionesList', this.opcionesList);
    this.facturacionSrv.getListaFac(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;
        console.log('__getListaFac() resp', resp);
        this.facturasList = resp.data;
        this.rows = this.facturasList;
        this.temp = [...this.facturasList];
      })

  }

  getListaCli() {
    this.loading = true;
    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data;
      })
  }


  // Búscar y filtrar

  filtro(val: string, campo: string) {

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d[campo]) return d[campo].toLowerCase().indexOf(val) !== -1 || !val;
      return !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.facturasList;
  }

  buscar(event: any, campo: string) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.filtro(txt, campo);
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.facturasList;
  }

  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr);
  }


  // Filtros

  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
    this.getListaFac();
  }



  enviar() {
    this.enviado = true;

    // validar formulario
    this.errores = {}

    if (this.fechaDesde && this.fechaHasta && new Date(this.fechaHasta) < new Date(this.fechaDesde)) {
      this.errores.fechaHasta = 'Debe ser mayor o igual a la fecha desde';
    }

    if (Object.keys(this.errores).length) {
      console.log('__enviar() ERRORES: ', Object.keys(this.errores).length);
      return
    }

    // Todo ok
    this.opcionesList = {};
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);
    this.opcionesList.fechaDesde = this.fechaDesde;
    this.opcionesList.fechaHasta = this.fechaHasta;
    this.getListaFac()
  }




  public sumaCeldas(cells: any[]) {
    // Devuelve la suma de la columna, formateada y sin decimales
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    // return sum.toLocaleString('es');
    return sum.toLocaleString('de-DE');
  }
  sumaCeldasEuro(cells: any[]) {
    // Devuelve la suma de la columna, formateada y con 2 decimales y símbolo '€'
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    return sum.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
  }


  //// CRUD ////
  deleteRow(row: any) {
    console.log('__deleteRow() row', row);

    Swal.fire({
      title: '¿Borrar este registro?',
      icon: 'question',
      html: `Eliminar este priodo de facturación de <b>${row.cliente!.nombre}</b>`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.facturacionSrv.facDelete(row.id)
          .subscribe(resp => {
            console.log('__deleteRow() resp', resp);
            this.getListaFac();
          })
      }
    })

  }
  ///// AUX  /////

  abrirPdf(pdf: string) {
    const url = environment.pdfDir + '/facturacion/' + pdf
    console.log('__abrirPdf()', url);
    window.open(url, '_blank');
  }

  abrirXls(xls: string) {
    const url = environment.xlsDir + '/facturacion/' + xls + '.xlsx'
    console.log('__abrirXls()', url);
    window.open(url);
  }


  // EXPORTAR A EXCEL
  exportToExcel(): void {
    const fileName = `facturacion-clientes-${new Date().toISOString()}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();

    // Forzar valores numéricos en las columnas Xlsx
    Object.entries(worksheet).forEach(([cell, cellObj]) => {
      if (!cell.startsWith('!') && !isNaN(cellObj.v) && cellObj.v !== '') {
        cellObj.v = Number(cellObj.v);
        cellObj.t = 'n';
      }
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  }


}
