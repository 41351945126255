import * as Const from '../../../shared/constants';

// Componentes
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable'

//Modelos e Interfaces
import { IAlmacen } from 'src/app/interfaces/almacenes';
import { ICliente } from 'src/app/interfaces/cliente';
import { IEntrada } from 'src/app/interfaces/entrada';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';
import { IStock } from 'src/app/interfaces/stock';

// Servicios
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { EntradasService } from 'src/app/services/datos/entradas.service';
import { TransaccionesService } from 'src/app/services/datos/transacciones.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { ModalRegularizarService } from 'src/app/services/modal-regularizar.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { environment } from 'src/environments/environment';

// Librerias
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-entradas-lista',
  templateUrl: './entradas-lista.component.html',
  styleUrls: ['./entradas-lista.component.scss']
})
export class EntradasListaComponent implements OnInit {

  // Usar constantes en el template
  Const = Const

  loading: boolean = false;
  textoDestacado: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }


  public pdfDir: string = environment.pdfDir;

  entradasList: IEntrada[] = [];
  almacenesList: IAlmacen[] = [];
  clientesList: ICliente[] = [];

  rows: any[] = [];
  temp: any[] = [];
  selected: any[] = [];

  columns = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  opcionesList: IListaOpciones = {}

  almSeleccionado: string = this.usuarioSrv.userdata.alm_por_defecto || '0';    // Almacén por defecto
  cliSeleccionado: string = '';

  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  // public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimitStr: string = '5';
  public rowsLimit: number = parseInt(this.rowsLimitStr);


  @ViewChild(EntradasListaComponent) table: EntradasListaComponent | any;

  constructor(
    private router: Router,
    public entradaSrv: EntradasService,
    private transacSrv: TransaccionesService,
    public almacenesSrv: AlmacenesService,
    public clientesSrv: ClientesService,
    public usuarioSrv: UsuarioService,
    public modalRegulSrv: ModalRegularizarService,
    private notificacionesSrv: NotificacionesService
  ) {
  }


  ngOnInit(): void {

    console.log('entradaSrv.selected', this.entradaSrv.selected)

    this.getListaEntradas();

    this.almacenesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.almacenesList = respuesta.data;
      })

    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data;
      })


  }



  getListaEntradas() {
    this.opcionesList.id_almacen = parseInt(this.almSeleccionado);
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);
    this.opcionesList.swCompleto = 'isNull';   // Solo las entradas con artículos pendientes de ubicar
    this.loading = true;

    this.entradaSrv.getLista(this.opcionesList)
      .subscribe(respuesta => {
        this.loading = false;
        this.entradasList = respuesta.data;

        // Seleccionar la línea del servicio, si no hay ninguna, seleccionar la primera de la lista

        console.log('this.entradaSrv.selected en getListaEntradas() ', this.entradaSrv.selected)
        console.log('this.entradasList[0]', this.entradasList[0])

        if (this.entradaSrv.selected) {
          const index = this.entradasList.findIndex(item => item.id === this.entradaSrv.selected.id);
          this.selected = index !== -1 ? [this.entradasList[index]] : [this.entradasList[0]];
        } else {
          this.selected = [this.entradasList[0]];
        }

        this.rows = this.entradasList;
        this.temp = [...this.entradasList];
      })

  }


  // Búscar y filtrar

  filtro(val: string, campo: string) {

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d[campo]) return d[campo].toLowerCase().indexOf(val) !== -1 || !val;
      return !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.entradasList;
  }

  buscar(event: any, campo: string) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.filtro(txt, campo);
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.entradasList;
  }


  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }

  onAlmacenSelect(id: string) {
    this.almSeleccionado = id;
    this.getListaEntradas();
  }

  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
    this.getListaEntradas();
  }



  //  CRUD  //

  editRow(row: IEntrada) {
    this.notificacionesSrv.aviso('info', `editar id ${row.id}`)
  }

  deleteRow(row: any) {
    Swal.fire({
      title: '¿Borrar registro de entrada?',
      icon: 'question',
      html: `Eliminar albaran <b>${row.albaran}</b>`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.entradaSrv.delete(row.id)
          .subscribe(resp => {

            console.log('__delete()', resp);

            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
            } else {
              this.creaTransacDeleteEntrada(row);  // hay que crear transacción negativa
              this.notificacionesSrv.aviso('info', `Registro eliminado`);
              this.rows = this.rows.filter((objeto) => objeto.id !== row.id);

              this.selected = [];
              // No quedan más entradas?
              if (!this.rows.length) {
                this.entradasList = [];
              }

            }
          })
      }
    })


  }

  creaTransacDeleteEntrada(row: IEntrada) {
    // Crear transacciopnes negativas

    const data = {
      id_entrada: row.id,
      username: this.usuarioSrv.userdata.username,
    }

    this.loading = true;
    this.transacSrv.TransacDeleteEntrada(data).subscribe(resp => {
      this.loading = false;
      if (resp.error) this.notificacionesSrv.aviso('error', resp.mensaje);

      console.log('__TransaccionEntrada(data)', data)
      console.log('__TransaccionEntrada(resp)', resp)

    })
  }


  onSelect(selected: any) {
    console.log('Select Event', selected, this.selected);
    this.entradaSrv.selected = this.selected[0];
  }

  infoClickEntrada(row: any) {
    console.log('__infoClickEntrada()', row);
    this.entradaSrv.selected = row;
  }


  editEntrada(id: number) {
    this.router.navigate(['/panel/entrada_edit', id]);
  }

  // Imprimir etiqueta de los palés
  etiquetaPale(row: any) {

    Swal.fire({
      title: 'Imprimir etiquetas de palé',
      icon: 'question',
      html: `¿Quieres imprimir las etiquetas para los palés de esta entrada?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('Imprimir etiqueta los palés ', row)

        const data = {
          entrada: row,
          id_usuario: this.usuarioSrv.userdata.id
        };

        // this.loading = true;
        // this.RepartoSrv.pdfEtiquetaMulti(data)
        //   .subscribe(resp => {
        //     this.loading = false;
        //     console.log('__pdfEtiquetaMulti()', resp);
        //     if (resp.error) {
        //       this.notificacionesSrv.aviso('error', resp.mensaje);
        //       return
        //     }
        //     this.notificacionesSrv.aviso('success', resp.mensaje);
        //   });

      }
    })
  }



  //  AUX //


  resaltarTexto(value: string, textoDestacado: string) {
    if (!textoDestacado) {
      return value;
    }
    return value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>');
  }


  // Exportar a Excel
  exportToExcel(): void {
    const fileName = `entradas-${new Date().toISOString()}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();

    // Forzar valores numéricos en las columnas Xlsx
    Object.entries(worksheet).forEach(([cell, cellObj]) => {
      if (!cell.startsWith('!') && !isNaN(cellObj.v) && cellObj.v !== '') {
        cellObj.v = Number(cellObj.v);
        cellObj.t = 'n';
      }
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  }


  abrirPDF(entrada: IEntrada) {
    const url = this.pdfDir + '/albaranEntrada/' + entrada.pdf_albaran
    window.open(url, '_blank');
  }



  public sumaCeldas(cells: any[]): number {
    const filteredCells = cells.filter(cell => !!cell);
    const sum = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    return sum;
  }

  public nada(cells: any[]): string {
    return '';
  }

}
