<div [class.oculto]="modalPedidoCabeceraSrv.ocultarModal"
     class="fondo-modal-imagen ">

    <div class="modal fade show"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel1"
         style="display: block;"
         (click)="cerrarModalClickOutside($event)">

        <div class="modal-dialog modal-lg"
             role="document">

            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"
                        id="exampleModalLabel1">
                        <i class="fa-solid fa-clipboard-check"></i> Cabecera del pedido {{modalPedidoCabeceraSrv.pedido?.pedido_cli }}
                    </h4>
                    <button type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close">
                        <span (click)="cerrarModal()"
                              aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="card card-body">

                        <form [formGroup]="formCabeza"
                              (ngSubmit)="enviar()"
                              class="form-horizontal">




                            <div class="row">

                                <!-- tipoPedido -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['tipoPedido'].errors }">
                                        <label class="form-control-label">
                                            Tipo de pedido:
                                        </label>
                                        <select class="form-control"
                                                formControlName="tipoPedido">
                                            <option value="B2C"> Pedido tipo B2C</option>
                                            <option value="B2B"> Pedido tipo B2B</option>
                                        </select>
                                        <div *ngIf="enviado && f['tipoPedido'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['tipoPedido'].errors?.['apiError']">{{formCabeza.get('tipoPedido')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>


                                <!-- nombre -->
                                <div class="col-8">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['nombre'].errors }">
                                        <label class="form-control-label">
                                            Nombre y apellidos:
                                        </label>
                                        <input type="text"
                                               formControlName="nombre"
                                               class="form-control "
                                               suffix=" Horas"
                                               [ngClass]="{ 'form-control-danger': enviado && f['nombre'].errors }">
                                        <div *ngIf="enviado && f['nombre'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['nombre'].errors?.['apiError']">{{formCabeza.get('nombre')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>

                                <!-- direccion -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['direccion'].errors }">
                                        <label class="form-control-label">
                                            Dirección:
                                        </label>
                                        <input type="text"
                                               formControlName="direccion"
                                               class="form-control"
                                               [ngClass]="{ 'form-control-danger': enviado && f['direccion'].errors }">
                                        <div *ngIf="enviado && f['direccion'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['direccion'].errors?.['apiError']">{{formCabeza.get('direccion')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>


                                <!-- cp -->
                                <div class="col-2">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['cp'].errors }">
                                        <label class="form-control-label">
                                            CP:
                                        </label>
                                        <input type="text"
                                               formControlName="cp"
                                               class="form-control"
                                               [ngClass]="{ 'form-control-danger': enviado && f['cp'].errors }">
                                        <div *ngIf="enviado && f['cp'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['cp'].errors?.['apiError']">{{formCabeza.get('cp')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>


                                <!-- poblacion -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['poblacion'].errors }">
                                        <label class="form-control-label">
                                            Población:
                                        </label>
                                        <input type="text"
                                               formControlName="poblacion"
                                               class="form-control"
                                               [ngClass]="{ 'form-control-danger': enviado && f['poblacion'].errors }">
                                        <div *ngIf="enviado && f['poblacion'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['poblacion'].errors?.['apiError']">{{formCabeza.get('poblacion')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>


                                <!-- pais_iso -->
                                <div class="col-2">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['pais_iso'].errors }">
                                        <label class="form-control-label">
                                            Pais:
                                        </label>
                                        <select class="form-control"
                                                formControlName="pais_iso">
                                            <option value="ES"
                                                    class="highlight-ES">España</option>
                                            <option *ngFor="let item of paisesList"
                                                    [value]=item.iso
                                                    [ngClass]="{ 'highlight-ES': item.iso === 'ES' }">
                                                {{item.nombre}}
                                            </option>
                                        </select>
                                        <div *ngIf="enviado && f['pais_iso'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['pais_iso'].errors?.['apiError']">{{formCabeza.get('pais_iso')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>

                            </div> <!-- row -->



                            <div class="row">

                                <!-- telefono -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['telefono'].errors }">
                                        <label class="form-control-label">
                                            Teléfono:
                                        </label>
                                        <input type="text"
                                               formControlName="telefono"
                                               class="form-control "
                                               suffix=" Horas"
                                               [ngClass]="{ 'form-control-danger': enviado && f['telefono'].errors }">
                                        <div *ngIf="enviado && f['telefono'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['telefono'].errors?.['apiError']">{{formCabeza.get('telefono')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>

                                <!-- email -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['email'].errors }">
                                        <label class="form-control-label">
                                            eMail:
                                        </label>
                                        <input type="text"
                                               formControlName="email"
                                               class="form-control"
                                               [ngClass]="{ 'form-control-danger': enviado && f['email'].errors }">
                                        <div *ngIf="enviado && f['email'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['email'].errors?.['apiError']">{{formCabeza.get('email')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>



                                <!-- cod_servicio -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['cod_servicio'].errors }">
                                        <label class="form-control-label">
                                            Servicio:
                                        </label>
                                        <select class="form-control"
                                                formControlName="cod_servicio">
                                            <option *ngFor="let item of serviciosEnvioList"
                                                    [value]=item.codServicio
                                                    [ngClass]="{ 'highlight-inte': item.codServicio === 'inte' }">
                                                {{item.nombre}}
                                            </option>
                                        </select>
                                        <div *ngIf="enviado && f['cod_servicio'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['cod_servicio'].errors?.['apiError']">{{formCabeza.get('cod_servicio')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>







                            </div> <!-- row -->

                            <hr>


                            <div class="d-flex justify-content-between">

                                <button [disabled]="loading"
                                        type="button"
                                        (click)="cerrarModal()"
                                        class="btn waves-effect waves-light btn-rounded btn-secondary"
                                        data-dismiss="modal">Cancelar</button>

                                <button [disabled]="loading"
                                        type="submit"
                                        class="ml-2 btn waves-effect waves-light btn-rounded btn-success">
                                    <i class="fa fa-spinner fa-spin"
                                       *ngIf="loading"></i> Enviar</button>
                            </div>


                        </form>
                    </div>


                </div>
            </div>
        </div>
    </div>


</div>