<div class="row ">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">


                <!-- cabecera -->
                <div class="row mb-2">

                    <div class="col-6">
                        <div class="form-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   (keyup)="buscarTodo($event)" />
                        </div>
                    </div>

                    <div class="col-6">
                        <form>

                            <div class="row float-right">

                                <div class="col-sm-6">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group float-right">
                                        <div class="btn-group">

                                            <a type="button"
                                               [routerLink]="'/panel/almacen/new'"
                                               class="btn btn-outline-info btn-circle "
                                               data-toggle="tooltip"
                                               title="Nuevo">
                                                <i class="fa-solid fa-plus"></i>
                                            </a>

                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                </div>

                <div class="rounded border">
                    <!-- datatable -->
                    <ngx-datatable class="bootstrap"
                                   [rows]="rows"
                                   [limit]="rowsLimit"
                                   [loadingIndicator]="loading"
                                   [columnMode]="ColumnMode.force"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   [summaryRow]="false"
                                   [summaryHeight]="55"
                                   [summaryPosition]="'bottom'"
                                   [rowHeight]="'auto'"
                                   [messages]="messages"
                                   [reorderable]="true">



                        <!-- Id -->
                        <ngx-datatable-column name="Id"
                                              prop='id'
                                              [width]="70"
                                              [maxWidth]="70"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- ean -->
                        <ngx-datatable-column name="EAN"
                                              prop='ean'
                                              [width]="150"
                                              [maxWidth]="200">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- sku -->
                        <ngx-datatable-column name="Sku"
                                              prop='sku'
                                              [width]="150"
                                              [maxWidth]="200">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- descripcion -->
                        <ngx-datatable-column name="Descripción"
                                              prop='descripcion'
                                              [width]="300"
                                              [maxWidth]="500">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- lote -->
                        <ngx-datatable-column name="Lote"
                                              prop='lote'
                                              [width]="150"
                                              [maxWidth]="200">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- cant_reserva -->
                        <ngx-datatable-column name="Cantidad"
                                              prop='cant_reserva'
                                              class="text-right"
                                              title="Cantidad reservada en todos los pedidos"
                                              [cellClass]="'text-right'"
                                              [maxWidth]="85"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- ubicacion -->
                        <ngx-datatable-column name="Ubicación"
                                              prop='ubicacion'
                                              [flexGrow]="160">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div class="monospace"
                                     [innerHTML]="resaltarTexto(value | mask: '00.00.00.00.00.00', textoDestacado | mask: '00.00.00.00.00.00')"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- añadir / editar / borrar -->
                        <ngx-datatable-column [width]="50"
                                              [maxWidth]="100"
                                              [sortable]="false"
                                              [resizeable]="false">


                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>

                                <a (click)="editRow(row)"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="EDITAR ubicación de recogida de este artículo">
                                    <span class="badge">E</span>
                                </a>

                            </ng-template>


                        </ngx-datatable-column>

                    </ngx-datatable>
                </div>



                <div class="card border border-dark rounded mt-4 p-1">
                    <div class="row">


                        <div class="col-md-6">
                            <div class="card bg-light">
                                <div class="card-body">
                                    <p class="card-text">
                                        <span class="h3">{{clienteInfo.nombre}}</span> <br>
                                        Campaña: {{campanyaInfo.camp_cod}} <span class="text-success">{{campanyaInfo.descripcion}} </span><br>
                                        Fecha de entrada: {{campanyaInfo.fecha_entrada}}
                                    </p>
                                    <hr>
                                    <p class="card-text">
                                        Total pedidos: {{campanyaInfo.tot_pedidos | number: '0.0-0':'es'}} <br>
                                        Total artículos: {{campanyaInfo.tot_articulos | number: '0.0-0':'es'}} <br>
                                        Total referencias: {{campanyaInfo.tot_referencias | number: '0.0-0':'es'}} <br>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class=" col-md-6">
                            <button [disabled]="loading"
                                    type="button"
                                    class="btn btn-outline-secondary btn-rounded mt-2"
                                    [routerLink]="'/panel/campanyas_lista'">Volver</button>
                        </div>

                    </div>
                </div>


            </div>
        </div>

    </div>