import * as Const from '../../../shared/constants';

// módulos
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// interfaces
import { IStock } from 'src/app/interfaces/stock';

// servicios
import { StockService } from 'src/app/services/datos/stock.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// librerías
import FuncLib from 'src/app/providers/funcionesLib';
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';


@Component({
  selector: 'app-stock-mover-articulo',
  templateUrl: './stock-mover-articulo.component.html',
  styleUrls: ['./stock-mover-articulo.component.scss']
})
export class StockMoverArticuloComponent implements OnInit {

  // Usar constantes en el template
  Const = Const

  public ubiForm: FormGroup = new FormGroup({});

  id_stock: string = '';
  public stock: IStock = {};

  stockList: IStock[] = [];
  stockDestinoList: IStock[] = [];
  public stockDestinoListErr: string = '';

  public ubiOrigen: string = '';
  id_ubiOrigen: number | null = null;

  public ubiDestino: string = '';

  public clienteOrigen: string = '';
  public clienteDestino: string = '';

  public enviado: boolean = false;
  public loading: boolean = false;

  public maximoAMover: number = 0;

  private funcLib: FuncLib = new FuncLib();
  public codUbiOriDecript: string = '';
  public codUbiDesDecript: string = '';

  ultimoAlmacen: string = '';
  public infoAlmacenOrigenObj: any = {};
  public infoAlmacenDestinoObj: any = {};

  constructor(
    private usuarioSrv: UsuarioService,
    private stockSrv: StockService,
    private almacenesSrv: AlmacenesService,
    private fb: FormBuilder,
    private notificacionesService: NotificacionesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,) { }


  ngOnInit(): void {
    this.getParametrosUrl();

    // Definir el form de ubicación
    this.ubiForm = this.fb.group({
      ubiDestino: ['', [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12)
      ]],
      cantidad: [0, [Validators.required]],
      facturable: [false],
    })


    // Suscribirse a los cambios en el campo almacen destino
    this.ubiForm.get('ubiDestino')?.valueChanges.subscribe(value => {
      if (typeof value === 'string' && value.length >= 2) {
        const nuevoAlmacen = value.substring(0, 2);
        if (this.ultimoAlmacen !== nuevoAlmacen) {
          this.ultimoAlmacen = nuevoAlmacen;
          this.onCambioAlmacen(nuevoAlmacen);
        }
      }
      if (!value) this.infoAlmacenDestinoObj = {};
    });
  }

  getParametrosUrl() {
    // Suscribirse a los parámetros de la url 
    this.activatedRoute.params.subscribe(({ id }) => {
      this.id_stock = id;
      this.getStock();
    })
  }



  // Enviar formulario al Backend
  send() {
    const factAux = this.ubiForm.get('facturable')?.value
    let facturable: string = '0';
    if (factAux) facturable = '1';

    const datos = {
      username: this.usuarioSrv.userdata.username,
      id_stock: this.stock.id,
      cantidad: this.ubiForm.get('cantidad')?.value,
      ubiOrigen: this.ubiOrigen,
      ubiDestino: this.ubiForm.get('ubiDestino')?.value,
      facturable: facturable
    };

    this.loading = true;
    this.stockSrv.stockMueveArticulo(datos).subscribe(resp => {
      this.loading = false;

      console.log(' __enviar datos', datos);
      console.log('__enviar resp', resp);

      if (resp.error) {
        this.notificacionesService.aviso('error', resp.mensaje)
        for (let controlName in resp.mensaje) {
          this.ubiForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
        }
      } else {
        this.notificacionesService.aviso('success', `${resp.mensaje}`);
        this.router.navigate(['./panel/stock_lista']);
      }

    })

  }


  enviar() {
    this.enviado = true;

    if (this.ubiForm.invalid) {
      console.log("ERRORES:", this.ubiForm);
      return;
    }

    if (this.clienteDestino && this.clienteDestino !== this.clienteOrigen) {
      this.ubiForm.get('ubiDestino')!.setErrors({ clienteNotMatch: true });
      return;
    }

    const cantidad = this.ubiForm.get('cantidad')?.value;
    if (cantidad > this.maximoAMover) {
      this.ubiForm.get('cantidad')!.setErrors({ cantidadExcede: true });
      return;
    }

    //Confirmar movimiento entre almacenes
    if (this.ubiOrigen.substring(0, 2) !== this.ubiDestino.substring(0, 2)) {
      Swal.fire({
        title: "Movimiento entre almacenes",
        text: "¿Estás seguro de querer hacer un movimientro entre almacenes?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, continuar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.send();
        } else {
          this.notificacionesService.aviso('info', 'Movimiento cancelado');
          return;
        }
      });
    } else {
      this.send();
    }
  }


  getStock() {
    this.loading = true;
    this.stockSrv.getRec(parseInt(this.id_stock))
      .subscribe(resp => {

        console.log('__getStock()', resp)

        this.loading = false;

        if (resp.error) {
          this.notificacionesService.aviso('error', resp.mensaje);
        } else {
          if (resp.data.cantidadDisponible < 1) {
            this.notificacionesService.aviso('error', 'No hay ninguna cantidad disponible a mover para esta referencia en esta ubicación');
            this.router.navigate(['./panel/stock_lista']);

          }
          this.stock = resp.data;
          this.ubiOrigen = this.stock.ubiCodigo!;
          this.id_ubiOrigen = resp.data.id_ubicacion!;
          this.clienteOrigen = this.stock.clienteNombre || '';


          // Actualizar el campo cantidad del formulario con el valor de resp.data.cantidad
          this.maximoAMover = resp.data.cantidadDisponible
          this.ubiForm.patchValue({
            cantidad: this.maximoAMover
          });

          this.cambiaUbiOri();
          this.getListaStock();
        }
      })
  }


  getListaStock() {
    // Lista del Stock en ORIGEN, solo hay un registro, el seleccionado
    this.stockList = [];
    this.stockList.push(this.stock);
  }


  getListaStockDestino() {
    // Lista del Stock en DESTINO
    const opcionesList: any = {}
    opcionesList.codUbi = this.ubiDestino;
    this.loading = true;
    this.stockDestinoListErr = ''
    this.clienteDestino = ''

    this.stockSrv.getLista(opcionesList)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          this.enviado = true;
          this.notificacionesService.aviso('error', resp.mensaje);
          this.stockDestinoListErr = resp.mensaje;
          this.ubiForm.get('ubiDestino')!.setErrors({ 'apiError': resp.mensaje });
        } else {
          this.stockDestinoList = resp.data;
          if (this.stockDestinoList && this.stockDestinoList.length > 0) {
            this.clienteDestino = this.stockDestinoList[0].clienteNombre || '';
          } else {
            this.clienteDestino = '';
          }
        }
      });
  }


  public cambiaUbiDes() {
    this.ubiDestino = this.ubiForm.get('ubiDestino')?.value;
    this.codUbiDesDecript = this.funcLib.CodUbiDecript(this.ubiDestino);

    if (this.ubiDestino.length === 12) {
      this.getListaStockDestino();
    } else {
      this.stockDestinoList = [];
      this.clienteDestino = ''
    }
  }

  // Solo cambia al cargar los datos
  public cambiaUbiOri() {
    this.codUbiOriDecript = this.funcLib.CodUbiDecript(this.ubiOrigen);
    if (this.ubiOrigen && this.ubiOrigen.length >= 2) {
      this.almacenesSrv.detallCod(this.ubiOrigen.substring(0, 2)).subscribe(resp => {
        this.infoAlmacenOrigenObj = resp.data;
      });
    }
  }


  // Cambia el almacen de una ubicación
  onCambioAlmacen(nuevoAlm: string) {
    console.log('Las 2 primeras posiciones de almacen_desde han cambiado:', nuevoAlm);
    this.almacenesSrv.detallCod(nuevoAlm).subscribe(resp => {
      console.log('__onCambioAlmacenDesde', resp);
      this.infoAlmacenDestinoObj = resp.data;
    })
  }


  get f() {
    return this.ubiForm.controls;
  }

}
