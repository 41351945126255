import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

// Servicios
import { UsuarioService } from 'src/app/services/datos/usuario.service';

// Modelos
import { IUsuario } from 'src/app/models/usuario';

// Librerías
import { ColumnMode } from '@swimlane/ngx-datatable'
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { Subscription, delay } from 'rxjs';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ModalImagenService } from 'src/app/services/modal-imagen.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-usr-lista',
  templateUrl: './usr-lista.component.html',
  styleUrls: ['./usr-lista.component.scss']
})
export class UsrListaComponent implements OnInit, OnDestroy {

  public fotoDir: string = environment.fotoDir;
  public roles = [
    { valor: 'sa', texto: 'Super Admin' },
    { valor: 'admin', texto: 'Admin' },
    { valor: 'usuario', texto: 'Usurio' },
    { valor: 'cliente', texto: 'Cliente' }
  ];

  public imgSubs: Subscription | undefined;

  textoDestacado: string = '';
  public strBuscarTodo: string = '';
  loading: boolean = false;

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  usuarios: any[] = [];
  rows: any[] = [];
  temp: any[] = [];

  columns = [];
  ColumnMode = ColumnMode;

  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  @ViewChild(UsrListaComponent) table: UsrListaComponent | any;

  constructor(
    private router: Router,
    public usuarioSrv: UsuarioService,
    private notificacionesSrv: NotificacionesService,
    private modalImagenSrv: ModalImagenService,
  ) { }

  ngOnInit(): void {

    this.loading = true;
    this.usuarioSrv.getLista().subscribe(resp => {
      console.log('__getLista():', resp)
      this.loading = false;
      this.usuarios = resp.data;
      this.rows = this.usuarios;
      this.temp = [...this.usuarios];
    })

    // Nos suscribimos a la imagen, 
    // Si cambia la imagen de algún usuario, buscamos esa id.usuario en this.usuarios[]

    this.imgSubs = this.modalImagenSrv.nuevaImagen
      .pipe(delay(100))
      .subscribe(img => {
        const indice = this.usuarios.findIndex(u => u.id === img.id);
        this.usuarios[indice].foto = img.guardado_como;
      });
  }


  ngOnDestroy(): void {
    this.imgSubs?.unsubscribe();
  }


  filtro(val: string, campo: string) {

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d[campo]) return d[campo].toLowerCase().indexOf(val) !== -1 || !val;
      return !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.usuarios;
  }


  // CRUD //

  editRow(row: IUsuario) {
    this.notificacionesSrv.aviso('info', `editar id ${row.id}`)
  }

  abrirModal(row: IUsuario) {
    this.modalImagenSrv.abrirModal('users', row.id!.toString(), row.foto);
  }

  buscar(event: any, campo: string) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.filtro(txt, campo);
  }

  buscaRol(evento: any) {
    const txt: string = evento.value;
    this.filtro(txt, 'rol');
  }


  deleteRow(row: IUsuario) {
    if (row.rol == 'admin') {
      this.notificacionesSrv.aviso('error', 'No puedes eliminar un usuario con rol Administrador');
      return
    }
    Swal.fire({
      title: '¿Borrar usuario?',
      icon: 'question',
      html: `Eliminar <b>${row.nombre}</b><br><i>Rol: ${row.rol}</i>`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.usuarioSrv.deleteUsr(row.id!)
          .subscribe(resp => {
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
            } else {
              this.notificacionesSrv.aviso('info', `Registro eliminado`);
              this.rows = this.rows.filter((objeto: any) => objeto.id !== row.id);
            }
          })
      }
    })
  }


  /// Aux ///

  irAEditarUsuario(usuario: IUsuario) {
    const defaultData = {
      volver_a: '/panel/usr_lista',
    }

    this.router.navigateByUrl(`panel/usuario/${usuario.id}`, { state: defaultData });
  }



  limpiaBusca() {
    this.strBuscarTodo = '';
    this.buscaGlobal('');
    const buscaTodoInput = document.getElementById('buscaTodo');
    buscaTodoInput?.focus();
  }

  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.usuarios;
  }


  buscaGlobal(txt: string) {
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.usuarios;
  }



  resaltarTexto(value: string, textoDestacado: string) {
    if (!textoDestacado) {
      return value;
    }
    return value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>');
  }



  // EXPORTAR A EXCEL
  exportToExcel(): void {
    const fileName = `Usuarios-${new Date().toISOString()}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  }

}
