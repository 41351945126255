<!-- <div *ngIf="loading"
     class="row mt-4">
  <div class="col-12">
    <div class="alert alert-info text-center"
         role="alert">
      <i class="fa fa-spin fa-refresh fa-2x"></i>
      <h4 class="alert-heading">Cargando datos...</h4>
    </div>
  </div>
</div> -->



<div class="row animated fadeIn fast">

  <div class="col-12">
    <div class="card border rounded animated fadeIn fast">

      <div class="card-body">


        <!-- cabecera -->
        <div class="row mb-2">

          <div class="col-6">
            <div class="form-group border border-dark rounded">
              <input class="form-control"
                     type="text"
                     autofocus
                     placeholder="Buscar cualquier dato..."
                     (keyup)="buscarTodo($event)" />
            </div>
          </div>

          <div class="col-6">
            <form>

              <div class="row float-right">

                <div class="col-sm-6">
                  <button type="button"
                          (click)="openIntranetCliente()"
                          class="btn btn-outline-info "
                          data-toggle="tooltip">
                    <i class="fa-solid fa-network-wired"></i>
                    Intranet del Cliente
                  </button>
                </div>

                <div class="col-sm-3">
                  <div class="form-group rounded">
                    <select class="form-control"
                            name="rowsLimitStr"
                            [(ngModel)]="rowsLimitStr"
                            (change)="rowsLimitChange()">
                      <option [value]="10">10</option>
                      <option [value]="50">50</option>
                      <option [value]="100">100</option>
                      <option [value]="999999">Todo</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="form-group float-right">
                    <div class="btn-group">

                      <a type="button"
                         [routerLink]="'/panel/cliente_edit/new'"
                         class="btn btn-outline-info btn-circle"
                         data-toggle="tooltip"
                         title="Nuevo">
                        <i class="fa-solid fa-plus"></i>
                      </a>

                      <button type="button"
                              (click)="exportToExcel()"
                              class="btn btn-outline-info btn-circle"
                              data-toggle="tooltip"
                              title="Descargar Excel">
                        <i class="fa-solid fa-download"></i>
                      </button>

                    </div>
                  </div>
                </div>

              </div>

            </form>
          </div>

        </div>


        <div class="rounded border">

          <!-- datatable -->
          <ngx-datatable class='bootstrap'
                         [style.width]="'100%'"
                         [columns]="columns"
                         [columnMode]="'force'"
                         [columnMode]="ColumnMode.force"
                         [loadingIndicator]="loading"
                         [headerHeight]="70"
                         [footerHeight]="50"
                         [rowHeight]="50"
                         [limit]="rowsLimit"
                         [rows]='rows'
                         [messages]="messages">


            <!-- id -->
            <ngx-datatable-column name="id"
                                  [width]="1"
                                  [maxWidth]="100"
                                  [resizeable]="false">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <div class="header noSearch"
                     (click)="sort()">
                  <h6 class="title link">id</h6>
                </div>
              </ng-template>
              <ng-template let-value="value"
                           ngx-datatable-cell-template>
                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
              </ng-template>
            </ngx-datatable-column>


            <!-- foto -->
            <ngx-datatable-column name="foto"
                                  [width]="1"
                                  [maxWidth]="100"
                                  [resizeable]="false"
                                  [sortable]="false">
              <ng-template ngx-datatable-header-template
                           let-column="column">
                <div class="header noSearch">
                </div>
              </ng-template>
              <ng-template let-value="value"
                           let-row="row"
                           ngx-datatable-cell-template>
                <div>
                  <img [src]="fotoDir +'/clientes/' + value"
                       loading="lazy"
                       height="25px"
                       alt="Foto"
                       title="Foto">
                </div>
              </ng-template>
            </ngx-datatable-column>


            <!-- nombre -->
            <ngx-datatable-column name="nombre"
                                  [width]="150"
                                  [maxWidth]="300">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'nombre')"><br>
                <div class="header"
                     (click)="sort()">
                  <h6 class="title link">Nombre</h6>
                </div>
              </ng-template>

              <ng-template let-value="value"
                           let-row="row"
                           ngx-datatable-cell-template>

                <span *ngIf="row.url"
                      style="display: inline-block;">
                  <a href="{{row.url}}"
                     target="_blank"
                     title="Abrir {{row.url}}">
                    <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                  </a>
                </span>

                <span *ngIf="!row.url"
                      style="display: inline-block;">
                  <div [innerHTML]="resaltarTexto(value, textoDestacado)">
                  </div>
                </span>

                <span *ngIf="row.acceso_intranet === 'S'"
                      class="text-secondary ml-2"
                      style="display: inline-block;"
                      title="Con acceso a la intranet de Clientes">
                  <i class="fa-solid fa-network-wired"></i>
                </span>

              </ng-template>

            </ngx-datatable-column>



            <!-- email -->
            <ngx-datatable-column name="email"
                                  [width]="100"
                                  [maxWidth]="200">

              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'email')"><br>
                <div class="header"
                     (click)="sort()">
                  <h6 class="title link">eMail</h6>
                </div>
              </ng-template>

              <ng-template ngx-datatable-cell-template
                           let-rowIndex="rowIndex"
                           let-value="value"
                           let-row="row">
                <span title="Doble click para editar"
                      class="editable loadVal"
                      (dblclick)="editing[rowIndex + '-email'] = true"
                      *ngIf="!editing[rowIndex + '-email']">
                  <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                </span>

                <div class="form-group">
                  <input autofocus
                         (blur)="updateValue($event, 'email', rowIndex)"
                         *ngIf="editing[rowIndex + '-email']"
                         type="text"
                         [value]="value"
                         class="form-control-sm editando" />
                </div>

              </ng-template>
            </ngx-datatable-column>



            <!-- telefono -->
            <ngx-datatable-column name="telefono"
                                  [width]="100"
                                  [maxWidth]="150">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'telefono')"><br>
                <div class="header"
                     (click)="sort()">
                  <h6 class="title link">Teléfono</h6>
                </div>
              </ng-template>
              <ng-template let-value="value"
                           ngx-datatable-cell-template>
                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
              </ng-template>
            </ngx-datatable-column>


            <!-- almacen_desde -->
            <ngx-datatable-column name="Reserva espacio desde"
                                  prop='almacen_desde'
                                  [width]="170"
                                  [maxWidth]="170">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'almacen_desde')"><br>
                <div class="header"
                     (click)="sort()">
                  <h6 class="title link"
                      title="Espacio reservado en almacén desde ubicación">Res. espacio desde</h6>
                </div>
              </ng-template>
              <ng-template let-value="value"
                           ngx-datatable-cell-template
                           class="mt-30">
                <div style="font-family: 'Verdana', monospace; letter-spacing: 0.2px;"
                     [innerHTML]="resaltarTexto(value | mask: '00.00.00.00.00.00', textoDestacado | mask: '00.00.00.00.00.00')"></div>
              </ng-template>
            </ngx-datatable-column>


            <!-- almacen_hasta -->
            <ngx-datatable-column name="Reserva espacio hasta"
                                  prop='almacen_hasta'
                                  [width]="170"
                                  [maxWidth]="170">
              <ng-template ngx-datatable-header-template
                           let-sort="sortFn"
                           let-column="column">
                <input type="search"
                       class="form-control form-control-sm"
                       placeholder="Buscar..."
                       (keyup)="buscar($event, 'almacen_hasta')"><br>
                <div class="header"
                     (click)="sort()">
                  <h6 class="title link"
                      title="Espacio reservado en almacén hasta ubicación">Res. espacio hasta</h6>
                </div>
              </ng-template>
              <ng-template let-value="value"
                           ngx-datatable-cell-template>
                <div style="font-family: 'Verdana', monospace; letter-spacing: 0.2px;"
                     [innerHTML]="resaltarTexto(value | mask: '00.00.00.00.00.00', textoDestacado | mask: '00.00.00.00.00.00')"></div>
              </ng-template>
            </ngx-datatable-column>


            <!-- Acciones -->
            <ngx-datatable-column [width]="150"
                                  [maxWidth]="160"
                                  [sortable]="false"
                                  [resizeable]="false">


              <ng-template let-row="row"
                           let-value="value"
                           ngx-datatable-cell-template>

                <a [routerLink]="['/panel/cliente_edit',row.id]"
                   class="link mr-2 pointer"
                   title="EDITAR cliente">
                  <span class="badge">E</span>
                </a>

                <a [routerLink]="['/panel/cliente_edit_fac',row.id]"
                   class="link mr-2 pointer"
                   title="Configurador de FACTURACIÓN">
                  <span class="badge">Cf</span>
                </a>

                <a [routerLink]="['/panel/cliente_edit_intranet',row.id]"
                   class="link mr-2 pointer"
                   title="Configurador de INTRANET del cliente">
                  <span class="badge">Ci</span>
                </a>

                <a (click)="row.acceso_intranet === 'S' ? modalAvisoCli(row) : null"
                   class="link mr-2 pointer"
                   data-toggle="tooltip"
                   title="Mandar AVISO desde la intranet del cliente"
                   [ngClass]="{'disabled': row.acceso_intranet !== 'S'}">
                  <span class="badge">A</span>
                </a>

                <!--
                <a (click)="deleteRow(row)"
                   class="link mr-2 pointer"
                   title="Borrar">
                  <i class="fa-regular fa-trash-can fa"></i>
                </a>
                 -->

              </ng-template>


            </ngx-datatable-column>

          </ngx-datatable>

        </div>


      </div>
    </div>
  </div>

</div>