<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Cargando...</h3>
</div>


<div *ngIf="loadingAlbaran"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Generando albarán...</h3>
</div>


<div class="row animated fadeIn fast">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">


                <!-- cabecera  -->
                <div class="row mb-2">

                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   id="buscaTodo"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   [(ngModel)]="strBuscarTodo"
                                   (keyup)="buscarTodo()" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary"
                                        type="button"
                                        title="Limpiar búsqueda"
                                        (click)="limpiaBusca()">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>



                    <!-- pedidosSearch -->
                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   id="buscaPedido"
                                   placeholder="Buscar pedido.."
                                   [(ngModel)]="pedidosSearch"
                                   (keydown.enter)="buscarPedido($event)" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary"
                                        type="button"
                                        title="Limpiar búsqueda"
                                        (click)="limpiaBuscaPedido()">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>



                    <!-- cliSeleccionado -->
                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon"
                                 title="Cliente"
                                 style="cursor: default"><i class="fa-solid fa-user"></i></div>
                            <select class="form-control"
                                    [(ngModel)]="cliSeleccionado"
                                    (ngModelChange)="onClienteSelect($event)">
                                <option value=''>Todos los clientes</option>
                                <option *ngFor="let cli of clientesList"
                                        [value]=cli.id>
                                    {{cli.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- estadoSeleccionado -->
                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon"
                                 title="Estado de la Recogida"
                                 style="cursor: default">R</div>
                            <select class="form-control"
                                    [(ngModel)]="estadoSeleccionado"
                                    (ngModelChange)="onEstadoSelect($event)">
                                <option value=''>Todos los estados</option>
                                <option *ngFor="let estado of estadosList"
                                        [value]=estado.id>
                                    {{estado.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>


                    <!-- estadoPreparacionSeleccionado -->
                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon"
                                 title="Estado de la Preparación"
                                 style="cursor: default">P</div>
                            <select class="form-control"
                                    [(ngModel)]="estadoPreparacionSeleccionado"
                                    (ngModelChange)="onEstadoPreparacionSelect($event)">
                                <option value=''>Todos los estados</option>
                                <option *ngFor="let estado of estadosPreparacionList"
                                        [value]=estado.id>
                                    {{estado.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>


                    <div class="col-2">
                        <form>

                            <div class="row float-right">

                                <div class="col-sm-6">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group float-right">
                                        <div class="btn-group">

                                            <button *ngIf="gurdaVistaFlag"
                                                    type="button"
                                                    (click)="guardaOpcionesList()"
                                                    class="btn btn-outline-success btn-circle"
                                                    title="Limpiar opciones de la vista guardadas">
                                                <i class="fa-solid fa-thumbtack-slash"></i>
                                            </button>
                                            <button *ngIf="!gurdaVistaFlag"
                                                    type="button"
                                                    (click)="guardaOpcionesList()"
                                                    class="btn btn-outline-info btn-circle"
                                                    title="Guardar opciones de la vista">
                                                <i class="fa-solid fa-thumbtack"></i>
                                            </button>

                                            <a type="button"
                                               [routerLink]="'/panel/campanya_new'"
                                               class="btn btn-outline-info btn-circle "
                                               title="Nuevo">
                                                <i class="fa-solid fa-plus"></i>
                                            </a>

                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                </div>


                <div class="rounded border">
                    <!-- datatable -->
                    <ngx-datatable class="bootstrap"
                                   [style.width]="'100%'"
                                   [limit]="rowsLimit"
                                   [rows]="rows"
                                   [columnMode]="ColumnMode.force"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   [messages]="messages"
                                   rowHeight="auto"
                                   [summaryRow]="true"
                                   [summaryHeight]="50"
                                   [summaryPosition]="summaryPosition">



                        <!-- camp_cod -->
                        <ngx-datatable-column name="Código"
                                              prop='camp_cod'
                                              [width]="125"
                                              [maxWidth]="125"
                                              [resizeable]="false"
                                              [summaryFunc]="literalTotal">

                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div class="d-flex align-items-center">
                                    <span class="monospace"
                                          [ngClass]="{'text-warning': row.campanyaTipo === '1 a 1'}"
                                          [class.font-weight-bold]="row.destacarLinea"
                                          [class.text-primary]="row.destacarLinea"
                                          [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                                    <img *ngIf=" row.campanyaTipo === '1 a 1'"
                                         src="../../../assets/images/icon/1a1.png"
                                         height="20px"
                                         alt="1 a 1"
                                         class="d-inline-block ml-2">
                                </div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- created_at -->
                        <ngx-datatable-column name="Fecha"
                                              prop='created_at'
                                              [width]="180"
                                              [maxWidth]="180"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div class="monospace"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)">
                                </div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- cliNombre -->
                        <ngx-datatable-column name="Cliente"
                                              prop='cliNombre'
                                              [width]="160"
                                              [maxWidth]="200"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>


                            </ng-template>
                        </ngx-datatable-column>

                        <!-- estado -->
                        <ngx-datatable-column name="Recogida"
                                              prop='estado'
                                              [width]="140"
                                              [maxWidth]="140"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="value | campanyaEstado"></div>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- estado_preparacion -->
                        <ngx-datatable-column name="Preparación"
                                              prop='estado_preparacion'
                                              [width]="130"
                                              [maxWidth]="130"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="value | campanyaEstadoPreparacion"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- tot_pedidos -->
                        <ngx-datatable-column name="Pedidos"
                                              prop='tot_pedidos'
                                              class="text-right"
                                              title="Número de pedidos para esta campaña"
                                              [cellClass]="'text-right'"
                                              [maxWidth]="85"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- tot_articulos -->
                        <ngx-datatable-column name="Articulos"
                                              prop='tot_articulos'
                                              class="text-right"
                                              title="Suma de artículos en todos los pedidos"
                                              [cellClass]="'text-right'"
                                              [maxWidth]="85"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- tot_referencias -->
                        <ngx-datatable-column prop='tot_referencias'
                                              name="Refs."
                                              class="text-right"
                                              title='Número de referencias únicas en todos los pedidos'
                                              [cellClass]="'text-right'"
                                              [maxWidth]="85"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value | number: '0.0-0':'es', textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- descripcion -->
                        <ngx-datatable-column name="Descripción"
                                              prop='descripcion'
                                              [width]="200"
                                              [maxWidth]="460">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- Acciones -->
                        <ngx-datatable-column name="Acciones"
                                              [width]="151"
                                              [sortable]="false"
                                              [resizeable]="false">


                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>

                                <a (click)="infoClickCampanya(row)"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="INFORMACIÓN de esta campaña">
                                    <span class="badge"
                                          [ngClass]="{'infoSelect': row.id == campSel.id}">
                                        I
                                    </span>
                                </a>


                                <a (click)="['1', '2', '3'].includes(row.estado) && row.estado_preparacion == Constantes.ESTADO_CAMPANYA_PREPARACION_PENDIENTE ? deleteRow(row) : null"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="BORRAR esta campaña"
                                   [ngClass]="{'disabled': !(['1', '2', '3'].includes(row.estado) && row.estado_preparacion == Constantes.ESTADO_CAMPANYA_PREPARACION_PENDIENTE )}">
                                    <span class="badge">B</span>
                                </a>

                                <a (click)="['1', '2', '3'].includes(row.estado) && row.estado_preparacion == Constantes.ESTADO_CAMPANYA_PREPARACION_PENDIENTE ? editUbicaciones(row) : null"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="editar UBICACIONES de recogidas de productos para esta campaña"
                                   [ngClass]="{'disabled': !(['1', '2', '3'].includes(row.estado) && row.estado_preparacion == Constantes.ESTADO_CAMPANYA_PREPARACION_PENDIENTE )}">
                                    <span class="badge">U</span>
                                </a>

                                <a [routerLink]="['/panel/preparacion_new', row.id]"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="Info sobre RECOGIDAS de esta campaña">
                                    <span class="badge">R</span>
                                </a>


                                <a [routerLink]="['/panel/asignar_pedidos',row.id]"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="Info sobre PREPARACIONES de esta campaña">
                                    <span class="badge">P</span>
                                </a>


                                <a (click)=" ['1', '2'].includes(row.estado_preparacion) ? campanyaAvisarCliente(row) : null"
                                   class="link mr-2 pointer"
                                   data-toggle="tooltip"
                                   title="AVISAR al cliente sobre los pedidos pendientes de esta campaña"
                                   [ngClass]="{'disabled': ['1', '2'].includes(row.estado_preparacion) === false}">
                                    <span class="badge">A</span>
                                </a>


                            </ng-template>

                        </ngx-datatable-column>


                    </ngx-datatable>
                </div>

            </div>
        </div>
    </div>



    <!-- Tabla de detalle de la campaña seleccionada 
     Mostrar si existe lista de pedidos ó se está busando algo en la lista -->
    <div *ngIf="pedidosList.length || this.strBuscarTodoPedidos.length"
         class="col-12">
        <div class="card">
            <div class="card-body">

                <div class="row">

                    <div class="col-4 ">
                        <div class="border border-dark rounded p-2">
                            <h4 class="card-title">Detalle de la campaña <span class="text-success">{{campSel.descripcion}}</span>
                            </h4>
                            <h5 class="text-info">{{campSel.camp_cod}} - {{campSel.cliNombre}}
                                <span *ngIf="campSel.campanyaTipo==='1 a 1'"
                                      class="text-warning"><i>Campa a tipo pedido 1 a 1</i></span>
                            </h5>
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="input-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   id="buscaTodoPedidos"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   [(ngModel)]="strBuscarTodoPedidos"
                                   (keyup)="buscarTodoPedidos()" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary"
                                        type="button"
                                        title="Limpiar búsqueda"
                                        (click)="limpiaBuscaPedidos()">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>


                    </div>

                    <div class="col-2">
                        <div class="input-group border border-dark rounded  ">
                            <div class="input-group-addon"
                                 title="EAN"
                                 style="cursor: default"><i class="fa-solid fa-shapes"></i></div>
                            <select class="form-control w-25"
                                    [(ngModel)]="eanSeleccionado"
                                    (ngModelChange)="onEanSelect($event)">
                                <option value="">Todos los artículos</option>
                                <option *ngFor="let ean of eanArray"
                                        [value]=ean>
                                    {{ean}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <span class="mt-2"
                          *ngIf="sumaCantidadesEan">{{sumaCantidadesEan | number:'0.0-0'}} Unidades
                    </span>
                </div>

                <div class="bootstrap-table mt-1">

                    <div class="table-responsive">
                        <table class="table table-sm table-striped table-bordered table-hover">

                            <thead>
                                <tr>
                                    <th *ngFor="let col of colPedidosLabel"
                                        (click)="ordenarPedidosCol(col.key)"
                                        style="cursor: pointer">
                                        {{ col.label }}
                                        <span *ngIf="sortColumnPed === col.key">
                                            <i class="text-success"
                                               [ngClass]="sortDirectionPed === 'asc' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'">
                                            </i>
                                        </span>
                                    </th>
                                    <th> </th>
                                    <th>Estado</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>

                            <!-- Si hay detall y no está ubicado, mostrarlo -->
                            <tbody>
                                <ng-container *ngFor="let pedido of pedidosList; let i = index">
                                    <tr>
                                        <td style="text-align: right; padding-left: 10px">
                                            <span [innerHTML]="resaltarTextoPedidos(pedido.id?.toString() || '', textoDestacadoPedidos)"></span>
                                        </td>
                                        <td>
                                            <span [innerHTML]="resaltarTextoPedidos(pedido.pedido_cli?.toString() || '', textoDestacadoPedidos)"></span>
                                            <span *ngIf="pedido.swDefectuoso == Constantes.DEFECTUOSO">
                                                <i title="Defectuoso"
                                                   class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                            </span>
                                        </td>
                                        <td>
                                            <span [innerHTML]="resaltarTextoPedidos(pedido.tipoPedido?.toString() || '', textoDestacadoPedidos)"></span>
                                        </td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <span [innerHTML]="resaltarTextoPedidos(pedido.cajetin?.toString() || '', textoDestacadoPedidos)"></span>
                                        </td>
                                        <td>
                                            <span [innerHTML]="resaltarTextoPedidos(pedido.albaran?.toString() || '', textoDestacadoPedidos)"></span>
                                        </td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{ pedido.tot_articulos | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td style="text-align: right; padding-left: 10px">
                                            <div class="pl-25 mr-20 monospace">
                                                {{ pedido.tot_referencias | number: '0.0-0':'es'}}
                                            </div>
                                        </td>
                                        <td>
                                            <span [innerHTML]="resaltarTextoPedidos(pedido.cp?.toString() || '', textoDestacadoPedidos)"></span>
                                            -
                                            <span [innerHTML]="resaltarTextoPedidos(pedido.poblacion?.toString() || '', textoDestacadoPedidos)"></span>
                                        </td>
                                        <td>
                                            <span [innerHTML]="resaltarTextoPedidos(pedido.pais_iso?.toString() || '', textoDestacadoPedidos)"></span>
                                        </td>
                                        <td [ngClass]="{ 'highlight-inte': pedido.cod_servicio === 'inte' }">
                                            <span [innerHTML]="resaltarTextoPedidos(pedido.cod_servicio?.toString() || '', textoDestacadoPedidos)"></span>
                                        </td>
                                        <td>
                                            <span [innerHTML]="resaltarTextoPedidos(pedido.nombre?.toString() || '', textoDestacadoPedidos)"></span>
                                        </td>
                                        <td>
                                            <span *ngIf="pedido.aviso_cliente==='1'"
                                                  data-toggle="tooltip"
                                                  title="Cliente avisado, puede solicitar acciones especiales mientras esté en estado 'Pendiente'">
                                                <i class="fa-regular fa-bell"></i>
                                            </span>
                                        </td>
                                        <td>
                                            <div [innerHTML]=" pedido.estado | pedidoEstado "
                                                 title={{pedido.estado_at}}></div>
                                        </td>

                                        <td>
                                            <a (click)="infoClickPedido(pedido.id!)"
                                               class="link mr-2 pointer"
                                               title="INFORMACIÓN del pedido">
                                                <span class="badge"
                                                      [ngClass]="{'infoSelect': pedido.id == pedidoDetall.id}">
                                                    I
                                                </span>
                                            </a>

                                            <a (click)="['1', '8'].includes(pedido.estado!) ? abrirModalCabeza(pedido) : null"
                                               class="link mr-2 pointer"
                                               data-toggle="tooltip"
                                               title="MODIFICAR la CABECERA de este pedido"
                                               [ngClass]="{'disabled': !(['1', '8'].includes(pedido.estado!))}">
                                                <span class="badge">M</span>
                                            </a>


                                            <a (click)="(pedido.estado === '3' || pedido.estado === '5' ) ? descargaAlbaranClick(pedido.id!) : null"
                                               class="link mr-2 pointer"
                                               title="Descargar ALBARÁN"
                                               [ngClass]="{'disabled':( pedido.estado !== '3' && pedido.estado !== '5') }">
                                                <span class="badge">A</span>
                                            </a>

                                            <a (click)="(pedido.estado === '3' || pedido.estado === '5' ) ? openTracking(pedido.id!) : null"
                                               class="link mr-2 pointer"
                                               title="TRACKING del pedido"
                                               [ngClass]="{'disabled':( pedido.estado !== '3' && pedido.estado !== '5') }">
                                                <span class="badge">T</span>
                                            </a>

                                            <a (click)="pedido.estado === '1' ? accionEspecialPedidoClick(pedido.id!) : null"
                                               class="link mr-2 pointer"
                                               title="Ación ESPECIAL sobre el pedido"
                                               [ngClass]="{'disabled': pedido.estado !== '1'}">
                                                <span class="badge">E</span>
                                            </a>

                                            <a (click)="abrirModal(pedido)"
                                               class="link mr-2 pointer"
                                               title="Cantidad de PACKAGING utilizado">
                                                <span class="badge"
                                                      [ngClass]="{ 'yellow-bg': pedido.fac_pack_estado === '2' }">P</span>
                                            </a>

                                            <a (click)="(pedido.cod_servicio === 'inte') ? pdfEtiquetaPedido(pedido) : null"
                                               class="link mr-2 pointer"
                                               data-toggle="tooltip"
                                               title="Imprimir ETIQUETA de este pedido"
                                               [ngClass]="{'disabled': !(pedido.cod_servicio === 'inte')}">
                                                <span class="badge">L</span>
                                            </a>

                                            <a (click)="(pedido.estado === Constantes.ESTADO_PEDIDO_SERVIDO || pedido.estado === Constantes.ESTADO_PEDIDO_SERVIDO_PARCIALMENTE) ? generaXlsEnvio(pedido) : null"
                                               class="link mr-2 pointer"
                                               title="Exportar este pedido a DTX"
                                               [ngClass]="{'disabled':( pedido.estado !== Constantes.ESTADO_PEDIDO_SERVIDO  && pedido.estado !== Constantes.ESTADO_PEDIDO_SERVIDO_PARCIALMENTE) }">
                                                <span class="badge">D</span>
                                            </a>

                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td>Total: </td>
                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace">
                                            {{ pedidosList.length| number: '0.0-0':'es' }}
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace">
                                            {{ sumaTotArticulos | number: '0.0-0':'es' }}
                                        </div>
                                    </td>
                                    <td style="text-align: right; padding-left: 10px">
                                        <div class="pl-25 mr-20 monospace">
                                            {{ sumaTotReferencias | number: '0.0-0':'es' }}
                                        </div>
                                    </td>
                                    <td colspan="4"></td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>



                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    </div>




    <!-- Tabla de detalle del pedido -->
    <div id="detallesPedido"
         *ngIf="pedidoDetall.pedido_cli"
         class="col-12">
        <app-pedido-detall [pedidoDetall]="pedidoDetall"
                           [eanSeleccionado]="eanSeleccionado"></app-pedido-detall>
    </div>




</div>


<!-- modal para facturación de pedidos -->
<app-modal-fact-salida></app-modal-fact-salida>



<!-- modal para edición de la cabecera del pedido -->
<app-modal-pedido-cabecera></app-modal-pedido-cabecera>