import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// interfaces
import { ICliente } from 'src/app/interfaces/cliente';
import { IUsuario } from 'src/app/models/usuario';

// servicios
import { AuxDataService } from 'src/app/services/datos/auxData.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// librerías
import CustomVal from 'src/app/providers/CustomValidators';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-cliente-edit-intranet',
  templateUrl: './cliente-edit-intranet.component.html',
  styleUrls: ['./cliente-edit-intranet.component.scss']
})
export class ClienteEditIntranetComponent implements OnInit {

  cliente: ICliente = {}
  paramId: string = '';

  public fotoDir: string = environment.fotoDir;
  public imgSubs: Subscription | undefined;

  public cliForm: FormGroup = new FormGroup({});
  usuariosList: IUsuario[] = [];

  public enviado: boolean = false;
  public loading: boolean = false;


  constructor(
    private clienteSrv: ClientesService,
    public usuarioSrv: UsuarioService,
    private auxDataSrv: AuxDataService,
    private notificacionesService: NotificacionesService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }


  public serviciosList: any[] = [];
  public codServicios_posibles: any[] = [];
  public codServicios_seleccionados: any[] = [];

  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos del cliente
    this.activatedRoute.params.subscribe(({ id }) => {
      this.paramId = id;
      this.cargarCliente(id);
    })

    this.declaraForm();
    this.getServiciosList();

  }

  declaraForm() {
    // Declara el fom reactivo
    this.cliForm = this.fb.group({
      acceso_intranet: ['N', Validators.required],
      agrupa_ubicaciones: [0],
      intranet_import_pedidos: [0],
      intranet_origen_pedidos: [0],
      email_intranet: ['', [CustomVal.ValidateEmail]],
      codServicio_pred: ['0'],
      codServicios_posibles: ([])
    })
  }

  cargarCliente(id: number) {
    this.loading = true;
    this.clienteSrv.getCliente(id)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesService.aviso('error', resp.mensaje);
          this, this.usuarioSrv.logOut();
        } else {
          this.cliente = resp.data;

          console.log('__cargarCliente() resp', resp);

          this.cliForm.patchValue({
            acceso_intranet: this.cliente.acceso_intranet,
            agrupa_ubicaciones: this.cliente.agrupa_ubicaciones,
            intranet_import_pedidos: this.cliente.intranet_import_pedidos,
            intranet_origen_pedidos: this.cliente.intranet_origen_pedidos,
            codServicio_pred: this.cliente.codServicio_pred,

            email_intranet: this.cliente.email_intranet
          })

          // Actualizar los check's de codServicios_seleccionados en el form
          if (this.cliente.codServicios_posibles) {
            const codServicios = JSON.parse(this.cliente.codServicios_posibles);
            this.codServicios_seleccionados = [...codServicios];

            this.cliForm.get('codServicios_posibles')?.setValue(this.codServicios_seleccionados);
          }

          this.getUsuariosList();

        }
      })
  }


  // Get lista de usuaios de tipo 'cliente'
  getUsuariosList() {
    if (!this.cliente.id) return;
    this.loading = true;
    this.usuarioSrv.getUsrCliList(this.cliente.id!)
      .subscribe(respuesta => {
        this.loading = false;
        this.usuariosList = respuesta.data;
      })
  }

  // Get lista de tipos de servicio
  getServiciosList() {
    this.loading = true;
    this.auxDataSrv.getListaServicios()
      .subscribe(respuesta => {
        this.loading = false;
        this.serviciosList = respuesta.data;
      })
  }



  enviar() {
    this.enviado = true;

    if (this.cliForm.invalid) {
      console.log("ERRORES:", this.cliForm);
      return;
    }

    // Todo ok, enviar al BackEnd  
    const data = {
      ...this.cliForm.value,
      id: this.cliente.id,
    };

    console.log('__actualizaCliIntranet() data=', data)

    this.loading = true;
    this.clienteSrv.actualizaCliIntranet(data)
      .subscribe(resp => {
        this.loading = false;

        console.log('__actualizaCliIntranet()', resp)

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log("errores desde la API:", resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.cliForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesService.aviso('success', resp.mensaje);
          this.router.navigateByUrl(`panel/clientes_lista`);
        }
      })
  }



  newUser() {
    if (this.cliForm.dirty) {

      const respuesta = Swal.fire({
        title: '¿Descartar cambios?',
        text: `Has hecho cambios en los datos del cliente, ¿Quieres descartarlos e ir a crear el nuevo usuario?`,
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'No, seguir editando cliente',
        confirmButtonText: 'Si, descartar cambios',
      })

      respuesta.then((result) => {
        if (result.isConfirmed) {
          console.log('__newUser() confirma descartar cambios');
          this.router.navigateByUrl(`panel/usuario/new`, { state: this.getDefaultData() });
        }
        return
      });
    } else {
      this.router.navigateByUrl(`panel/usuario/new`, { state: this.getDefaultData() });
    }

  }

  private getDefaultData() {
    return {

      rol: 'cliente',
      rolIntranet: 'admin',
      id_cliente: this.cliente.id,
      alm_por_defecto: this.cliente.alm_por_defecto,
      volver_a: '/panel/cliente_edit_intranet/' + this.cliente.id,
    };
  }

  irAEditarUsuario(usuario: IUsuario) {
    const defaultData = {
      volver_a: '/panel/cliente_edit_intranet/' + this.cliente.id,
    }

    this.router.navigateByUrl(`panel/usuario/${usuario.id}`, { state: defaultData });
  }
  onCodServicioChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    const value = checkbox.value;

    if (checkbox.checked) {
      this.codServicios_seleccionados.push(value);
    } else {
      this.codServicios_seleccionados = this.codServicios_seleccionados.filter(
        option => option !== value
      );
    }

    this.cliForm.get('codServicios_posibles')?.setValue(this.codServicios_seleccionados);


    // si no hay nada seleccionado, poner el predeternminado como nada

    if (this.codServicios_seleccionados.length === 0) {
      this.cliForm.get('codServicio_pred')?.setValue('0');
    }
  }

  accesoIntranetChange() {
    this.cliForm.get('codServicios_posibles')?.setErrors(null);
  }



  // AUX  //

  get f() {
    return this.cliForm.controls;
  }


}
