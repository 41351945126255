import * as Const from '../../../shared/constants';

// Componentes  
import { identifierName } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable'

//Modelos e Interfaces
import { IAlmacen } from 'src/app/interfaces/almacenes';
import { ICliente } from 'src/app/interfaces/cliente';
import { IStock } from 'src/app/interfaces/stock';

// Servicios
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { StockService } from 'src/app/services/datos/stock.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { ModalRegularizarService } from 'src/app/services/modal-regularizar.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// Librerias
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';


interface Editing {
  [key: string]: boolean;
}
@Component({
  selector: 'app-stock-list-historico',
  templateUrl: './stock-list-historico.component.html',
  styleUrls: ['./stock-list-historico.component.scss']
})
export class StockListHistoricoComponent implements OnInit {

  // Usar constantes en el template
  Const = Const

  loading: boolean = false;
  textoDestacado: string = '';
  public strBuscarTodo: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  fechaStock: string = '';
  stockList: IStock[] = [];
  almacenesList: IAlmacen[] = [];
  clientesList: ICliente[] = [];

  rows: any[] = [];
  temp: any[] = [];

  columns = [];
  ColumnMode = ColumnMode;

  opcionesList: {
    fechaHistorico?: string,
    id_cliente?: number,
    id_almacen?: number,
    id_articulo?: number,
    id_ubicacion?: number
  } = {}

  almSeleccionado: string = this.usuarioSrv.userdata.alm_por_defecto || '0';    // Almacén por defecto
  cliSeleccionado: string = '';

  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);


  @ViewChild(StockListHistoricoComponent) table: StockListHistoricoComponent | any;

  constructor(
    public stockSrv: StockService,
    public almacenesSrv: AlmacenesService,
    public clientesSrv: ClientesService,
    public usuarioSrv: UsuarioService,
    public modalRegulSrv: ModalRegularizarService,
    private notificacionesSrv: NotificacionesService,
  ) { }


  ngOnInit(): void {

    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1); // Restar un día a la fecha actual
    this.fechaStock = yesterday.toISOString().substring(0, 10); // Obtener la fecha en formato YYYY-MM-DD

    this.getListaStock();

    this.almacenesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.almacenesList = respuesta.data;
      })

    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data;
      })

  }


  getListaStock() {
    this.opcionesList.fechaHistorico = this.fechaStock;
    this.opcionesList.id_almacen = parseInt(this.almSeleccionado);
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);
    this.loading = true;

    this.stockSrv.getListaHistorico(this.opcionesList)
      .subscribe(respuesta => {
        this.loading = false;
        this.stockList = respuesta.data;

        console.log('getLista', this.stockList);

        this.rows = this.stockList;
        this.temp = [...this.stockList];
      })

  }


  // Búscar y filtrar

  filtro(val: string, campo: string) {

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d[campo]) return d[campo].toLowerCase().indexOf(val) !== -1 || !val;
      return !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.stockList;
  }

  buscar(event: any, campo: string) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.filtro(txt, campo);
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.buscaGlobal(txt);
  }



  dblclickCampo(valor: any) {
    this.strBuscarTodo = String(valor);
    const txt = valor.toLowerCase() ?? '';
    this.buscaGlobal(txt);
  }


  buscaGlobal(txt: string) {
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.stockList;
  }

  limpiaBusca() {
    this.strBuscarTodo = '';
    this.buscaGlobal('');
    const buscaTodoInput = document.getElementById('buscaTodo');
    buscaTodoInput?.focus();
  }

  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }


  onFechaChange(newDate: string) {
    this.fechaStock = newDate;
    this.getListaStock();
  }

  onAlmacenSelect(id: string) {
    this.almSeleccionado = id;
    this.opcionesList.id_almacen = parseInt(id);
    this.getListaStock();
  }

  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
    this.opcionesList.id_cliente = parseInt(id);
    this.getListaStock();
  }


  //  AUX //


  resaltarTexto(value: string, textoDestacado: string) {
    if (!textoDestacado) {
      return value;
    }
    return value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>');
  }




  // EXPORTAR A EXCEL
  exportToExcel(): void {
    const fileName = `Stock-historico-${new Date().toISOString()}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();

    // Forzar valores numéricos en las columnas Xlsx
    Object.entries(worksheet).forEach(([cell, cellObj]) => {
      if (!cell.startsWith('!') && !isNaN(cellObj.v) && cellObj.v !== '') {
        cellObj.v = Number(cellObj.v);
        cellObj.t = 'n';
      }
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  }


  public sumaCeldas(cells: any[]) {
    // Devuelve la suma de la columna, formateada y sin decimales
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    // return sum.toLocaleString('es');
    return sum.toLocaleString('de-DE');

  }


}
