import { UbicacionesService } from './../../../services/datos/ubicaciones.service';

// Módulos
import { Component, OnInit, ViewChild } from '@angular/core';

// Interfaces y Modelos
import { IAlmacen } from 'src/app/interfaces/almacenes';
import { IUbicacion, IUbiTipo } from 'src/app/interfaces/ubicacion';

// Servicios
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';

// Librerías
import { ColumnMode } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';

import * as XLSX from 'xlsx';
@Component({
  selector: 'app-ubicaciones',
  templateUrl: './ubicaciones.component.html',
  styleUrls: ['./ubicaciones.component.scss']
})
export class UbicacionesComponent implements OnInit {

  public lista: IUbicacion[] = [];
  almacenesList: IAlmacen[] = [];

  public rows: any = [];
  public temp: any[] = [];

  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  textoDestacado: string = '';
  loading = false;

  opcionesList: {
    id_cliente?: number,
    id_almacen?: number,
    id_articulo?: number,
    id_ubicacion?: number
  } = {}

  almSeleccionado: string = this.usuarioSrv.userdata.alm_por_defecto || '0';    // Almacén por defecto

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  ColumnMode = ColumnMode;
  @ViewChild(UbicacionesComponent) table: UbicacionesComponent | any;

  constructor(
    private ubiSrv: UbicacionesService,
    private notificacionesSrv: NotificacionesService,
    private usuarioSrv: UsuarioService,
    private almacenSrv: AlmacenesService
  ) { }

  ngOnInit(): void {
    this.cargaLista();
    this.cargaAlmacenes();
  }

  cargaLista() {
    this.opcionesList.id_almacen = parseInt(this.almSeleccionado);
    this.loading = true;
    this.ubiSrv.getListaFiltrada(this.opcionesList)
      .subscribe(respuesta => {
        this.loading = false;
        this.lista = respuesta.data;

        console.log(' this.opcionesList', this.opcionesList);
        console.log('  respuesta.data', respuesta.data);

        this.rows = this.lista;
        this.temp = [...this.lista];
      })
  }

  cargaAlmacenes() {
    this.almacenSrv.getLista()
      .subscribe(respuesta => {
        this.almacenesList = respuesta.data;
      })
  }


  //  CRUD  // 

  deleteRow(row: any) {
    const formattedCodigo = row.codigo.replace(/(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})\d$/, '$1.$2.$3.$4.$5');

    Swal.fire({
      title: '¿Borrar ubicación?',
      icon: 'question',
      html: `Eliminar <b>${formattedCodigo}</b>`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.ubiSrv.delete(row.id)
          .subscribe(resp => {
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
            } else {
              this.notificacionesSrv.aviso('info', `Registro eliminado`);
              this.rows = this.rows.filter((objeto: any) => objeto.id !== row.id);
            }
          })
      }
    })
  }



  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }

  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );
    this.textoDestacado = txt;
    this.rows = temp;
    this.table = this.lista;
  }

  resaltarTexto(value: string, textoDestacado: string) {
    if (!textoDestacado) {
      return value;
    }
    return value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>');
  }

  // EXPORTAR A EXCEL
  exportToExcel(): void {
    const fileName = `Ubicaciones-${new Date().toISOString()}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();

    // Forzar valores numéricos en las columnas Xlsx
    Object.entries(worksheet).forEach(([cell, cellObj]) => {
      if (!cell.startsWith('!') && !isNaN(cellObj.v) && cellObj.v !== '') {
        cellObj.v = Number(cellObj.v);
        cellObj.t = 'n';
      }
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  }


  onAlmacenSelect(id: string) {
    this.almSeleccionado = id;
    this.cargaLista();
  }

}

