import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

// interfaces
import { IPedido, IPedidoDetall } from 'src/app/interfaces/pedido';

// servicios
import { RepartosService } from 'src/app/services/datos/repartos.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-pedido-detall',
  templateUrl: './pedido-detall.component.html',
  styleUrls: ['./pedido-detall.component.scss']
})
export class PedidoDetallComponent implements OnInit {
  @Input() pedidoDetall: IPedido = {} // Detalle de un pedido  
  @Input() eanSeleccionado: string = '';
  //@Input() articuloDetall: any = {} // Detalle de un artículo en un pedido

  loading: boolean = false;
  public articuloDetall: any[] = [];
  public articData: any = null;


  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  imagenSeleccionada: string | null = null;


  constructor(
    private repartosSrv: RepartosService,
    private notificacionesSrv: NotificacionesService,
  ) { }

  ngOnInit(): void {
    // this.leerTextoConVoz('Hola mundo!'); 

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pedidoDetall']) {
      // Si cambia el pedido, resetea los detalles del artículo 
      this.articuloDetall = [];
    }
  }


  infoArticuloPedido(pedidoDetall: IPedidoDetall) {
    this.loading = true;
    this.articuloDetall = [];

    this.articData = pedidoDetall;
    const data = {
      id_pedido: this.articData.id_pedido,
      id_articulo: this.articData.id_articulo
    };
    this.repartosSrv.pedidoCajasRepartoDetall(data).subscribe(resp => {
      this.loading = false;
      console.log('__infoArticuloPedido() articData', this.articData);
      console.log('__infoArticuloPedido() resp', resp);

      if (resp.error) {
        this.notificacionesSrv.aviso('error', resp.mensaje || 'Error desconocido');
        this.articuloDetall = [];
        return
      }
      this.articuloDetall = resp.data || [];
    })

  }

  // AUX 

  hasArticuloDetall(): boolean {
    return Array.isArray(this.articuloDetall) && this.articuloDetall.length > 0;
  }

  leerTextoConVoz(txtVoz: string): void {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(txtVoz);
    synth.speak(utterance);
  }

  abrirPopupImagen(url: string) {
    console.log('__abrirPopupImagen()', url);
    if (url.includes('_noFoto')) return
    this.imagenSeleccionada = url;
  }

  cerrarPopupImagen() {
    this.imagenSeleccionada = null;
  }


}
